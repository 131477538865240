import React, { Component } from 'react';
import ReactDOMServer from "react-dom/server";

import { withRouter } from 'react-router-dom';
//import logo from './logo.svg';
import 'antd/dist/antd.css';
import { message, Layout, Row, Col, Select, Input, InputNumber, Switch, Card, Steps, Icon, Button, Menu, Dropdown, Modal, Table, Form, Radio } from 'antd';
//import { geolocated, geoPropTypes } from 'react-geolocated';
import { gloRootUrl, gloGetOptions, gloIsPresent, gloPrintIfPresent, gloDataUrl, gloDataPost, gloDataPut, gloDataPostWithResult, gloDataGet, gloGetTitleFromValue, gloRenderPrice, gloGetTrunklineTitleFromValue, gloGetGlycolUnitTitleFromValue } from './Common.js';
import * as InstallationUtils from './InstallationUtils.js';
import * as ExportUtils from './ExportUtils.js';
import CustomerEditForm from './CustomerEditForm.js';
import CustomerSearchForm from './CustomerSearchForm.js';
import ContractorEditForm from './ContractorEditForm.js';
import ContractorSearchForm from './ContractorSearchForm.js';
import AddressText from './AddressText.js';

const queryString = require('query-string');
const Option = Select.Option;
const { Header, Footer, Content } = Layout;
const Step = Steps.Step;
const CustomerEditFrm = Form.create()(CustomerEditForm);
const CustomerSearchFrm = Form.create()(CustomerSearchForm);
const ContractorEditFrm = Form.create()(ContractorEditForm);
const ContractorSearchFrm = Form.create()(ContractorSearchForm);

const rowHeaderWidth = 8;
const rowDataWidth = 14;

const componentName = 'MobileApp';

var root_path = '';

//const { history } = require("react-router-dom");

export default class MobileApp extends Component {

    constructor() {
        super();
        this.state = {
            step: 0,
            steps: [],
            quoteId: 0,
            /* Customer search */
            customerSearchDatasource: [],
            customerSelectDatasource: [],
            /* Settings */
            settingsJobType: "cleaning",
            settingsIsContractor: true,
            settingsServiceAddress: '',
            settingsServiceCity: '',
            settingsServiceState: '',
            settingsServiceZip: '',
            /* Customer */
            customerId: -1,
            customerName: '',
            customerAddress: '',
            customerCity: '',
            customerState: '',
            customerZip: '',
            customerEmail: '',
            customerPhone: '',
            customerOwner: '',
            customerOwnerCell: '',
            customerManager: '',
            customerManagerCell: '',
            /* Contractor */
            contractorId: -1,
            contractorName: '',
            contractorContact: '',
            contractorPhone: '',
            contractorEmail: '',
            /* Cleaning */
            cleaningNumberOfTowers: 0,
            cleaningNumberOfHookups: 0,
            cleaningNumberOfFacuets: 0,
            /* Installation */
            installationNumberOfTowers: 0,
            installationTowers: [],
            installationTowerValues: [],
            installationTowerTypeValues: [],
            installationTowerFaucetsValues: [],
            installationTowerFaucetsCustomValues: [],
            installationDripTrays: [],
            installationDripTraysCostPerTray: [],
            installationIsLacingKit: false,
            installationLacingKit: 0,
            installationNumberOfTrunklines: 0,
            installationTrunklines: [],
            installationTrunklineValues: [],
            installationTrunklineTypes: [],
            installationTrunklineYs: [],
            installationTrunklineManifolds: [],
            installationTrunklineManifoldsQuantity: [],
            installationNumberOfGlycolUnits: 0,
            installationGlycolUnits: [],
            installationGlycolUnitValues: [],
            installationIsAirSource: true,
            installationIsBlenderBox: false,
            installationBlenderBoxPrimaryCo2Regulator: true,
            installationBlenderBoxPrimaryN2Regulator: false,
            installationBlenderBoxPrimaryRegulator: "primary_co2_regulator",

            installationBlenderBoxPrimaryCo2RegulatorQty: 1,
            installationBlenderBoxPrimaryN2RegulatorQty: 1,
            installationBlenderBoxPrimaryRegulatorQty: 1,

            installationBlenderBlocks: '',
            installationIsDoubleportLeakDetector: false,
            installationDoubleportLeakDetector: 1,
            installationIsSingleportLeakDetector: false,
            installationSingleportLeakDetector: 1,
            installationWalkinPanelsType: '',
            installationWalkinPanelsQuantity: 0,

            installationWalkinPanelsPpSrpWFab: false,
            installationWalkinPanelsPpSrpWFabQty: 1,
            installationWalkinPanelsPpSrpNoFabs: false,
            installationWalkinPanelsPpSrpNoFabsQty: 1,

            installationGlycolJumperLine: 0,
            installationLabor: 0,
            /* Misc */
            miscLocationSurcharge: 0,
            miscStartUpAndSiteSurvey: 0,
            miscDiscounting: 0,
            miscUptick: 0,
            /* modal */
            isModalVisible: false,
            modalQuotes: [],
            /* presentation */
            isShowResult: false,
            isShowCalculate: false,
            calculateResult: '',
            /* customer forms */
            customerEditFrmIsOpen: false,
            customerSearchFrmIsOpen: false,
            /* customer forms */
            contractorEditFrmIsOpen: false,
            contractorSearchFrmIsOpen: false,
            token: localStorage["appState"]
                ? JSON.parse(localStorage["appState"]).user.auth_token
                : "",
        }
    }


    componentDidMount() {

        console.log("componentDidMount props started");

        console.log("componentDidMount props", this.props);

        root_path = window.location.href;
        console.log("componentDidMount root_path", root_path);
        let idx = root_path.lastIndexOf('/');
        root_path = root_path.slice(0, idx);
        console.log("componentDidMount root_path 2", root_path);


        if (this.props.location && this.props.location.search) {
            let p = queryString.parse(this.props.location.search, { ignoreQueryPrefix: true });
            console.log("componentDidMount root_path 3", p);

            if (p && p.id) {
                gloDataGet(componentName, `${gloDataUrl}/quotes/${p.id}?token=${this.state.token}`, this.onQuotesItemLoaded);
            }
        }

        this.getSteps();
    }

    onQuotesItemLoaded = (p_data) => {
        console.log("onQuotesItemLoaded p_data", p_data);
        if (p_data && p_data !== "") {
            this.onModalQuoteSelect(undefined, p_data.data);
        }
    }

    getNumberedSelect = (p_name, p_func, p_value) => {
        let options = [];
        for (let i = 0; i < 11; i++) {
            options.push(<Option value={i} key={`${p_name}${i}`}>{i}</Option>);
        }

        if (undefined === p_func) {
            return (
                <Select defaultValue={p_value} style={{ width: 220 }}>
                    {options}
                    <Option value={-1}>Custom</Option>
                </Select>
            );

        } else {
            return (
                <Select defaultValue={p_value} style={{ width: 220 }} value={p_value} onChange={p_func}>
                    {options}
                    <Option value={-1}>Custom</Option>
                </Select>
            );
        }
    }

    getArray = (p_length, p_data, p_default) => {

        let res = [];
        console.log('getArray started', p_length, p_data)
        if (p_length > 0) {
            res = new Array(p_length).fill(p_default); // [];
            for (let i = 0; i < p_length; i++) {
                if (p_data.length > i) {
                    console.log('getArray', i, p_data[i], res)
                    res[i] = p_data[i];
                } else {
                    break;
                }
            }
        }
        console.log('getArray result', res)
        return res;
    }

    getNumArray = (p_length, p_data) => {
        return this.getArray(p_length, p_data, 0);
    }

    getTextArray = (p_length, p_data) => {
        return this.getArray(p_length, p_data, '');
    }

    getBoolArray = (p_length, p_data) => {
        return this.getArray(p_length, p_data, false);
    }

    getInstallationTowersSelect = (p_index) => {
        console.log("getInstallationTowersSelect p_index installationTowerValues", p_index, this.state.installationTowerValues);
        let options = [];
        for (let i = 0; i < 11; i++) {
            options.push(<Option value={i} key={`installation_facets_${p_index}_${i}`}>{i}</Option>);
        }

        let t_type = (this.state.installationTowerTypes && this.state.installationTowerTypes.length > p_index) ? this.state.installationTowerTypes[p_index] : '';
        let t_faucets = (this.state.installationTowerFaucetsValues && this.state.installationTowerFaucetsValues.length > p_index) ? this.state.installationTowerFaucetsValues[p_index] : '';
        let t_faucetsCustom = (this.state.installationTowerFaucetsCustomValues && this.state.installationTowerFaucetsCustomValues.length > p_index) ? this.state.installationTowerFaucetsCustomValues[p_index] : '';
        let t_dts = (this.state.installationDripTrays && this.state.installationDripTrays.length > p_index) ? this.state.installationDripTrays[p_index] : false;
        let t_cost = (this.state.installationDripTraysCostPerTray && this.state.installationDripTraysCostPerTray.length > p_index) ? this.state.installationDripTraysCostPerTray[p_index] : '';

        let tower_types = gloGetOptions('installation_tower_types', InstallationUtils.TowerTypes, p_index);

        return (
            <div>
                <div className="div-subitem"><b className="div-subitem-title">Type:</b>
                    <Select className="div-subitem-content" defaultValue={0} key={`installation_tower_types_${p_index}`} value={this.state.installationTowerTypeValues[p_index]} onChange={(x) => this.onInstallationTowerTypeChange(x, p_index)}>
                        {tower_types}
                    </Select>
                </div>
                <div className="div-subitem"><b className="div-subitem-title"># faucets:</b>
                    <Select className="div-subitem-content" defaultValue={0} key={`installation_facets_${p_index}`} value={this.state.installationTowerFaucetsValues[p_index]} onChange={(x) => this.onInstallationTowerFaucetsChange(x, p_index)}>
                        {options}
                        <Option value={-1}>Custom</Option>
                    </Select>
                </div>
                {t_faucets === -1
                    ? <InputNumber
                        className="div-subitem-content"
                        min={0}
                        step={1}
                        width={60}
                        value={t_faucetsCustom}
                        onChange={(x) => this.onInstallationTowerFaucetsCustomChange(x, p_index)} />
                    : ''
                }
                <div className="div-subitem"><b className="div-subitem-title">Drip Trays:</b>
                    <Switch
                        className="div-subitem-content"
                        checked={this.state.installationDripTrays[p_index]}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        style={{ width: 50 }}
                        onChange={(x) => this.onInstallationDripTraysChange(x, p_index)} />
                </div>
                <div className="div-subitem"><b className="div-subitem-title">Cost:</b>
                    <InputNumber
                        className="div-subitem-content"
                        min={0}
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        step={0.01}
                        value={this.state.installationDripTraysCostPerTray[p_index]}
                        onChange={(x) => this.onInstallationDripTraysCostPerTrayChange(x, p_index)} />
                </div>
            </div>

        );
    }

    getInstallationTrunklinesSelect = (p_index) => {
        console.log("getInstallationTrunklines p_index installationTrunklines", p_index, this.state.installationTrunklines);
        let options = [];
        for (let i = 0; i < 11; i++) {
            options.push(<Option value={i} key={`installation_trunklines_${p_index}_${i}`}>{i}</Option>);
        }
        let t_type = (this.state.installationTrunklineTypes && this.state.installationTrunklineTypes.length > p_index) ? this.state.installationTrunklineTypes[p_index] : '';
        //let t_kind = (this.state.installationTrunklineKinds && this.state.installationTrunklineKinds.length > p_index) ? this.state.installationTrunklineKinds[p_index] : '';
        let t_ys = (this.state.installationTrunklineYs && this.state.installationTrunklineYs.length > p_index) ? this.state.installationTrunklineYs[p_index] : '';
        let t_mf = (this.state.installationTrunklineManifolds && this.state.installationTrunklineManifolds.length > p_index) ? this.state.installationTrunklineManifolds[p_index] : '';
        let t_mq = (this.state.installationTrunklineManifoldsQuantity && this.state.installationTrunklineManifoldsQuantity.length > p_index) ? this.state.installationTrunklineManifoldsQuantity[p_index] : 0;

        console.log("getInstallationTrunklines p_index installationTrunklines values", p_index, t_type, t_ys, t_mf, t_mq);

        return (
            <div>
                <div className="div-subitem"><b className="div-subitem-title">Length:</b>
                    <div className="div-subitem-content">
                        <InputNumber
                            key={`installation_trunklines_${p_index}`}
                            min={0}
                            step={0.1}
                            style={{ width: 80 }}
                            value={this.state.installationTrunklineValues[p_index]}
                            onChange={(x) => this.onInstallationTrunklineChange(x, p_index)} /> (feet)
                    </div>
                </div>
                <div className="div-subitem"><b className="div-subitem-title">Type:</b>
                    <Select key={`installation_trunkline_types_${p_index}`} className="div-subitem-content" value={t_type} onChange={(x) => this.onInstallationTrunklineTypeChange(x, p_index)}>
                        <Option key={`installation_trunkline_types_${p_index}_crusader_2_4`} value={'crusader_2_4'}>Crusader 2+4</Option>
                        <Option key={`installation_trunkline_types_${p_index}_crusader_2_6`} value={'crusader_2_6'}>Crusader 2+6</Option>
                        <Option key={`installation_trunkline_types_${p_index}_crusader_2_8`} value={'crusader_2_8'}>Crusader 2+8</Option>
                        <Option key={`installation_trunkline_types_${p_index}_crusader_2_10`} value={'crusader_2_10'}>Crusader 2+10</Option>
                        <Option key={`installation_trunkline_types_${p_index}_crusader_4_10`} value={'crusader_4_10'}>Crusader 4+10</Option>
                    </Select>
                </div>
                {this.state.installationNumberOfTrunklines > 1
                    ? <div className="div-subitem"><b className="div-subitem-title">Ys:</b>
                        <Switch
                            className="div-subitem-content"
                            style={{ width: 50 }}
                            checked={this.state.installationTrunklineYs[p_index]}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(x) => this.onInstallationTrunklineYsChange(x, p_index)} />
                    </div>
                    : ''
                }
                {this.state.installationNumberOfTrunklines > 2
                    ? <div className="div-subitem"><b className="div-subitem-title">Manifolds:</b>
                        <Switch
                            className="div-subitem-content"
                            style={{ width: 50 }}
                            checked={this.state.installationTrunklineManifolds[p_index]}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={(x) => this.onInstallationTrunklineManifoldsChange(x, p_index)} />
                    </div>
                    : ''
                }
                {this.state.installationNumberOfTrunklines > 2 && t_mf
                    ? <div className="div-subitem"><b className="div-subitem-title">Quantity:</b>
                        <InputNumber
                            min={0}
                            width={80}
                            className="div-subitem-content"
                            value={t_mq}
                            onChange={(x) => this.onInstallationTrunklineManifoldsQuantityChange(x, p_index)} />
                    </div>
                    : ''
                }
            </div>
        );
    }

    getInstallationGlycolUnitSelect = (p_index) => {
        console.log("getInstallationGlycolUnitSelect p_index installationTrunklines", p_index, this.state.installationGlycolUnits);
        return (
            <Select key={`installation_glycol_unit_${p_index}`} style={{ width: 220 }} value={this.state.installationGlycolUnitValues[p_index]} onChange={(x) => this.onInstallationGlycolUnitChange(x, p_index)}>
                <Option key={`installation_glycol_unit_${p_index}_crusader_225`} value={'crusader_225'}>Crusader 225</Option>
                <Option key={`installation_glycol_unit_${p_index}_crusader_350`} value={'crusader_350'}>Crusader 350</Option>
                <Option key={`installation_glycol_unit_${p_index}_perlick_quickchill_1_3_hp`} value={'perlick_quickchill_1_3_hp'}>Perlick Quickchill 1/3 HP</Option>
                <Option key={`installation_glycol_unit_${p_index}_perlick_4404_1_2_hp`} value={'perlick_4404_1_2_hp'}>Perlick 4404 1/2 HP</Option>
            </Select>
        );
    }

    getTrunclineSelect = (p_name) => {
        let data = ["Crusader 2+4", "Crusader 2+6", "Crusader 2+8", "Crusader 2+10", "Crusader 4+10"];
        let options = data.map(function (x, i) {

            return (<Option key={`${p_name}_${i}`} value={i}>{x}</Option>);
        })
        return (
            <Select defaultValue={0} style={{ width: 200 }}>
                {options}
            </Select>
        );
    }

    getGlycolUnitSelect = (p_name) => {
        let data = ["Crusader 225", "Crusader 350", "Perlick Quickchill 1/3 HP"];
        let options = data.map(function (x, i) {

            return (<Option key={`${p_name}_${i}`} value={i}>{x}</Option>);
        })
        return (
            <Select defaultValue={0} style={{ width: 200 }}>
                {options}
            </Select>
        );
    }

    onStepClick = (value) => {
        console.log("onStepClick", value);

        //let step = this.state.steps.indexOf(value);
        let step = this.state.steps.findIndex(x => (x.key === value))
        if (0 <= step) {
            this.setState({ step: step });
        }
    }

    getSteps = () => {
        let result = [];

        result.push(<Step key="settings" title="Settings" onClick={() => this.onStepClick("settings")} />);
        result.push(<Step key="customer" title="Customer" onClick={() => this.onStepClick("customer")} />);

        if (this.state.settingsIsContractor) {
            result.push(<Step key="contractor" title="Contractor" onClick={() => this.onStepClick("contractor")} />);
        }

        if (this.state.settingsJobType === "cleaning") {
            result.push(<Step key="cleaning" title="Cleaning" onClick={() => this.onStepClick("cleaning")} />);
        } else {
            result.push(<Step key="installation" title="Installation" onClick={() => this.onStepClick("installation")} />);
        }

        result.push(<Step key="misc" title="Misc" onClick={() => this.onStepClick("misc")} />);
        result.push(<Step key="done" title="Done" onClick={() => this.onStepClick("done")} />);

        this.setState({ steps: [] }, () => this.setState({ steps: result }));
    }

    //#region Settings
    renderSettings = () => {
        return (
            <div>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Job Type</Col>
                    <Col span={6} className="col-data">
                        <Select defaultValue="cleaning" style={{ width: 150 }} value={this.state.settingsJobType} onChange={this.onSettingsJobTypeChange}>
                            <Option value="cleaning">Cleaning</Option>
                            <Option value="installation">Installation</Option>
                        </Select>
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Contractor</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <Switch checkedChildren="Yes" unCheckedChildren="No" checked={this.state.settingsIsContractor} onChange={this.onSettingsIsContractorChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Service Address</Col>
                    <Col span={6} className="col-data">
                        <Input style={{ width: 200, float: 'left' }} value={this.state.settingsServiceAddress} onChange={this.onSettingsServiceAddressChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Service City</Col>
                    <Col span={6} className="col-data">
                        <Input style={{ width: 200, float: 'left' }} value={this.state.settingsServiceCity} onChange={this.onSettingsServiceCityChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Service State</Col>
                    <Col span={6} className="col-data">
                        <Input style={{ width: 200, float: 'left' }} value={this.state.settingsServiceState} onChange={this.onSettingsServiceStateChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Service Zip</Col>
                    <Col span={6} className="col-data">
                        <Input style={{ width: 200, float: 'left' }} value={this.state.settingsServiceZip} onChange={this.onSettingsServiceZipChange} />
                    </Col>
                </Row>

            </div>
        );
    }
    onSettingsJobTypeChange = (value) => {
        this.setState({ settingsJobType: value }, () => this.getSteps());
    }

    onSettingsIsContractorChange = (checked) => {
        this.setState({ settingsIsContractor: checked }, () => this.getSteps());
    }

    onSettingsServiceAddressChange = (e) => {
        this.setState({ settingsServiceAddress: e.target.value });
    }

    onSettingsServiceCityChange = (e) => {
        this.setState({ settingsServiceCity: e.target.value });
    }

    onSettingsServiceStateChange = (e) => {
        this.setState({ settingsServiceState: e.target.value });
    }

    onSettingsServiceZipChange = (e) => {
        this.setState({ settingsServiceZip: e.target.value });
    }
    //#endregion

    //#region Customer
    renderCustomer = () => {
        return (
            <div>
                <div style={{ display: 'flex', marginLeft: 20 }}>
                    {this.state.customerId > 0
                        ? <a style={{ marginRight: 20 }} onClick={this.onCustomerEditFrmOpen}>
                            <Icon type="info-circle" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Edit Customer
                        </a>
                        : <a style={{ marginRight: 20 }} onClick={this.onCustomerEditFrmOpen}>
                            <Icon type="plus-circle" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Add new Customer
                        </a>
                    }
                    <a onClick={this.onCustomerSearchFrmOpen}>
                        <Icon type="hdd" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Select Customer
                    </a>
                </div>
                <Card
                    style={{ margin: 20, textAlign: 'left' }}
                    type="inner"
                    title={this.state.customerId < 0 ? 'No Customer Selected' : this.state.customerName}
                >
                    {
                        this.state.customerId < 0
                            ? ''
                            : <AddressText
                                title=''
                                address={this.state.customerAddress}
                                city={this.state.customerCity}
                                state={this.state.customerState}
                                zip={this.state.customerZip}
                                phone={this.state.customerPhone}
                                email={this.state.customerEmail}
                            />
                    }
                </Card>

            </div>
        );
    }

    onCustomerEditFrmSubmit = (p_data) => {
        console.log('onCustomerEditFrmSubmit p_data', p_data)
        if (p_data && p_data.data) {
            this.setState({
                customerAddress: p_data.data.address,
                customerCity: p_data.data.city,
                customerEmail: p_data.data.email,
                customerId: p_data.data.id,
                customerManager: p_data.data.manager,
                customerManagerCell: p_data.data.manager_cell,
                customerName: p_data.data.name,
                customerOwner: p_data.data.owner,
                customerOwnerCell: p_data.data.owner_cell,
                customerPhone: p_data.data.phone,
                customerState: p_data.data.state,
                customerZip: p_data.data.zip
            });
        } else {

        }
        this.setState({ customerEditFrmIsOpen: false });
    }

    onCustomerEditFrmOpen = () => {
        this.setState({ customerEditFrmIsOpen: true });
    }

    onCustomerEditFrmClose = () => {
        this.setState({ customerEditFrmIsOpen: false });
    }

    onCustomerSearchFrmSelect = (p_event, p_data) => {
        console.log('onCustomerSearchFrmSelect p_data', p_data)
        if (p_data) {
            this.setState({
                customerAddress: p_data.address,
                customerCity: p_data.city,
                customerEmail: p_data.email,
                customerId: p_data.id,
                customerManager: p_data.manager,
                customerManagerCell: p_data.manager_cell,
                customerName: p_data.name,
                customerOwner: p_data.owner,
                customerOwnerCell: p_data.owner_cell,
                customerPhone: p_data.phone,
                customerState: p_data.state,
                customerZip: p_data.zip
            });
        } else {

        }
        this.setState({ customerSearchFrmIsOpen: false });
    }

    onCustomerSearchFrmOpen = () => {
        this.setState({ customerSearchFrmIsOpen: true });

    }

    onCustomerSearchFrmClose = () => {
        this.setState({ customerSearchFrmIsOpen: false });
    }

    //#endregion

    //#region Contractor
    renderContractor = () => {
        return (
            <div>
                <div style={{ display: 'flex', marginLeft: 20 }}>
                    {this.state.contractorId > 0
                        ? <a style={{ marginRight: 20 }} onClick={this.onContractorEditFrmOpen}>
                            <Icon type="info-circle" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Edit Contractor
                        </a>
                        : <a style={{ marginRight: 20 }} onClick={this.onContractorEditFrmOpen}>
                            <Icon type="plus-circle" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Add new Contractor
                        </a>
                    }
                    <a onClick={this.onContractorSearchFrmOpen}>
                        <Icon type="hdd" theme="twoTone" twoToneColor="#1890ff" style={{ marginRight: 5 }} />Select Contractor
                    </a>
                </div>

                <Card
                    style={{ margin: 20, textAlign: 'left' }}
                    type="inner"
                    title={this.state.contractorId < 0 ? 'No Contractor Selected' : this.state.contractorName}
                >
                    {
                        this.state.contractorId < 0
                            ? ''
                            : <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                <div style={{ textAlign: 'left', lineHeight: 1 }}>
                                    {gloIsPresent(this.state.contractorContact)
                                        ? this.state.contractorContact
                                        : ''
                                    }
                                    <p>{gloPrintIfPresent(this.state.contractorEmail)}{(gloIsPresent(this.state.contractorEmail) && gloIsPresent(this.state.contractorPhone)) ? ' | ' : ''}{gloPrintIfPresent(this.state.contractorPhone)}</p>
                                </div>
                            </div>
                    }
                </Card>
            </div>
        );
    }

    onContractorEditFrmSubmit = (p_data) => {
        console.log('onContractorEditFrmSubmit p_data', p_data)
        if (p_data && p_data.data) {
            this.setState({
                contractorId: p_data.data.id,
                contractorName: p_data.data.name,
                contractorContact: p_data.data.contact,
                contractorPhone: p_data.data.phone,
                contractorEmail: p_data.data.email
            });
        } else {

        }
        this.setState({ contractorEditFrmIsOpen: false });
    }

    onContractorEditFrmOpen = () => {
        this.setState({ contractorEditFrmIsOpen: true });
    }

    onContractorEditFrmClose = () => {
        this.setState({ contractorEditFrmIsOpen: false });
    }

    onContractorSearchFrmSelect = (p_event, p_data) => {
        console.log('onContractorSearchFrmSelect p_data', p_data)
        if (p_data) {
            this.setState({
                contractorId: p_data.id,
                contractorName: p_data.name,
                contractorContact: p_data.contact,
                contractorPhone: p_data.phone,
                contractorEmail: p_data.email
            });
        } else {

        }
        this.setState({ contractorSearchFrmIsOpen: false });
    }

    onContractorSearchFrmOpen = () => {
        this.setState({ contractorSearchFrmIsOpen: true });

    }

    onContractorSearchFrmClose = () => {
        this.setState({ contractorSearchFrmIsOpen: false });
    }

    //#endregion

    //#region Cleaning
    renderCleaning = () => {
        return (
            <div>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># Towers</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <InputNumber
                            min={0}
                            step={1}
                            type="number"
                            style={{ width: 200, float: 'left' }}
                            value={this.state.cleaningNumberOfTowers}
                            onChange={this.onCleaningNumberOfTowersChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># Hookups</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <InputNumber
                            min={0}
                            step={1}
                            type="number"
                            style={{ width: 200, float: 'left' }}
                            value={this.state.cleaningNumberOfHookups}
                            onChange={this.onCleaningNumberOfHookupsChange} />
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># Facuets</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <InputNumber
                            min={0}
                            step={1}
                            type="number"
                            style={{ width: 200, float: 'left' }}
                            value={this.state.cleaningNumberOfFacuets}
                            onChange={this.onCleaningNumberOfFacuetsChange} />
                    </Col>
                </Row>
            </div>
        );
    }

    onCleaningNumberOfTowersChange = (p_value) => {
        this.setState({ cleaningNumberOfTowers: p_value });
    }

    onCleaningNumberOfHookupsChange = (p_value) => {
        this.setState({ cleaningNumberOfHookups: p_value });
    }

    onCleaningNumberOfFacuetsChange = (p_value) => {
        this.setState({ cleaningNumberOfFacuets: p_value });
    }
    //#endregion    

    //#region Installation
    renderInstallation = () => {
        return (
            <div>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># Towers</Col>
                    <Col span={rowDataWidth} className="col-data">
                        {this.getNumberedSelect("installation_number_of_towers", this.onInstallationNumberOfTowersChange, this.state.installationNumberOfTowers)}
                    </Col>
                </Row>
                {this.state.installationTowers}
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Lacing kit</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <Switch checked={this.state.installationIsLacingKit} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationIsLacingKitChange} />
                        {this.state.installationIsLacingKit
                            ? <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                step={0.01}
                                width={120}
                                style={{ marginLeft: 10, width: 120 }}
                                value={this.state.installationLacingKit}
                                onChange={this.onInstallationLacingKitChange} />
                            : ''
                        }
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># of Trunklines</Col>
                    <Col span={rowDataWidth} className="col-data">
                        {this.getNumberedSelect("installation_trunkline", this.onInstallationNumberOfTrunklinesChange, this.state.installationNumberOfTrunklines)}
                    </Col>
                </Row>
                {this.state.installationTrunklines}
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"># of Glycol units</Col>
                    <Col span={rowDataWidth} className="col-data">
                        {this.getNumberedSelect("installationGlycolUnits", this.onInstallationNumberOfGlycolUnitsChange, this.state.installationNumberOfGlycolUnits)}
                    </Col>
                </Row>
                {this.state.installationGlycolUnits}
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Air source</Col>
                    <Col span={rowDataWidth} className="col-data">
                        {/*
                        <Switch disabled checked={this.state.installationIsAirSource} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationIsAirSourceChange} />
                    */}
                    </Col>
                </Row>
                {this.state.installationIsAirSource
                    ? <Row className="row-data">
                        <Col span={rowHeaderWidth} className="col-header"><div style={{ marginLeft: 20 }}>Blender Box</div></Col>
                        <Col span={rowDataWidth} className="col-data">
                            <Switch className="div-subitem-switch" style={{ marginRight: 10 }} checked={this.state.installationIsBlenderBox} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationIsBlenderBoxChange} />

                            {this.state.installationIsBlenderBox
                                ? <div style={{ display: 'inline-block' }}>
                                    <div style={{ display: 'inline-block' }}><b>Primary Co2 Regulator(s):</b>
                                        <div style={{ display: 'inline-block' }}>
                                            <InputNumber
                                                min={1}
                                                step={1}
                                                width={120}
                                                style={{ width: 120, marginRight: 10 }}
                                                value={this.state.installationBlenderBoxPrimaryCo2RegulatorQty}
                                                onChange={this.onInstallationBlenderBoxPrimaryCo2RegulatorQtyChange} />
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-block' }}><b>Primary N2 Regulator(s):</b>
                                        <div style={{ display: 'inline-block' }}>
                                            <InputNumber
                                                min={1}
                                                step={1}
                                                width={120}
                                                style={{ width: 120 }}
                                                value={this.state.installationBlenderBoxPrimaryN2RegulatorQty}
                                                onChange={this.onInstallationBlenderBoxPrimaryN2RegulatorQtyChange} />
                                        </div>
                                    </div>
                                </div>
                                : <div style={{ display: 'inline-block' }}>
                                    <div style={{ display: 'inline-block' }}><b>Primary Co2 Regulator(s):</b>
                                        <Switch className="div-subitem-switch" checked={this.state.installationBlenderBoxPrimaryCo2Regulator} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationBlenderBoxPrimaryCo2RegulatorChange} />
                                        {this.state.installationBlenderBoxPrimaryCo2Regulator
                                            ? <InputNumber
                                                min={1}
                                                step={1}
                                                width={80}
                                                style={{ width: 80, marginLeft: 10, marginRight: 10 }}
                                                value={this.state.installationBlenderBoxPrimaryCo2RegulatorQty}
                                                onChange={this.onInstallationBlenderBoxPrimaryCo2RegulatorQtyChange} />
                                            : ''
                                        }
                                    </div>
                                    <div style={{ display: 'inline-block' }}><b>Primary N2 Regulator(s):</b>
                                        <Switch className="div-subitem-switch" checked={this.state.installationBlenderBoxPrimaryN2Regulator} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationBlenderBoxPrimaryN2RegulatorChange} />
                                        {this.state.installationBlenderBoxPrimaryN2Regulator
                                            ? <InputNumber
                                                min={1}
                                                step={1}
                                                width={80}
                                                style={{ width: 80, marginLeft: 10 }}
                                                value={this.state.installationBlenderBoxPrimaryN2RegulatorQty}
                                                onChange={this.onInstallationBlenderBoxPrimaryN2RegulatorQtyChange} />
                                            : ''
                                        }
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    : ''
                }
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"><div style={{ marginLeft: 20 }}>Singleport leak detector</div></Col>
                    <Col span={rowDataWidth} className="col-data">
                        <Switch className="div-subitem-switch" checked={this.state.installationIsSingleportLeakDetector} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationIsSingleportLeakDetectorChange} />
                        {this.state.installationIsSingleportLeakDetector
                            ? <InputNumber
                                min={1}
                                step={1}
                                width={80}
                                style={{ width: 80, marginLeft: 10 }}
                                value={this.state.installationSingleportLeakDetector}
                                onChange={this.onInstallationSingleportLeakDetectorChange} />
                            : ''
                        }
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header"><div style={{ marginLeft: 20 }}>Doubleport leak detector</div></Col>
                    <Col span={rowDataWidth} className="col-data">
                        <Switch className="div-subitem-switch" checked={this.state.installationIsDoubleportLeakDetector} checkedChildren="Yes" unCheckedChildren="No" onChange={this.onInstallationIsDoubleportLeakDetectorChange} />
                        {this.state.installationIsDoubleportLeakDetector
                            ? <InputNumber
                                min={1}
                                step={1}
                                width={80}
                                style={{ width: 80, marginLeft: 10 }}
                                value={this.state.installationDoubleportLeakDetector}
                                onChange={this.onInstallationDoubleportLeakDetectorChange} />
                            : ''
                        }
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Walk in Panels</Col>
                    <Col span={rowDataWidth} className="col-data">
                        <div style={{ display: 'inline-block' }}><b>PP SRP w Fab: </b>
                            <div style={{ display: 'inline-block' }}>
                                <Switch
                                    className="div-subitem-switch"
                                    checked={this.state.installationWalkinPanelsPpSrpWFab}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.onInstallationWalkinPanelsPpSrpWFabChange} />
                                {this.state.installationWalkinPanelsPpSrpWFab
                                    ? <InputNumber
                                        min={1}
                                        step={1}
                                        width={80}
                                        style={{ width: 80, marginLeft: 10, marginRight: 10 }}
                                        value={this.state.installationWalkinPanelsPpSrpWFabQty}
                                        onChange={this.onInstallationWalkinPanelsPpSrpWFabQtyChange} />
                                    : ''
                                }
                            </div>
                        </div>
                        <div style={{ display: 'inline-block' }}><b>PP SRP No Fabs: </b>
                            <div style={{ display: 'inline-block' }}>
                                <Switch
                                    className="div-subitem-switch"
                                    checked={this.state.installationWalkinPanelsPpSrpNoFabs}
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    onChange={this.onInstallationWalkinPanelsPpSrpNoFabsChange} />
                                {this.state.installationWalkinPanelsPpSrpNoFabs
                                    ? <InputNumber
                                        min={1}
                                        step={1}
                                        width={80}
                                        style={{ width: 80, marginLeft: 10 }}
                                        value={this.state.installationWalkinPanelsPpSrpNoFabsQty}
                                        onChange={this.onInstallationWalkinPanelsPpSrpNoFabsQtyChange} />
                                    : ''
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Glycol Jumper line</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            step={0.1}
                            style={{ width: 140, float: 'left', marginRight: 10 }}
                            value={this.state.installationGlycolJumperLine}
                            onChange={this.onInstallationGlycolJumperLineChange} /> (feet)
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Labor</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            step={0.1}
                            style={{ width: 140, float: 'left', marginRight: 10 }}
                            value={this.state.installationLabor} onChange={this.onInstallationLaborChange} /> (days)
                    </Col>
                </Row>
            </div>
        );
    }

    onInstallationNumberOfTowersChange = (p_value) => {
        console.log("onInstallationNumberOfTowersChange installationTowers", p_value, this.state.installationTowers);
        let value = 0;
        let res = [];
        if (Number.isInteger(p_value)) {
            value = Number(p_value);
        }
        if (0 === value) {
            res = [];
            this.setState({
                installationNumberOfTowers: p_value,
                installationTowers: [],
                installationTowerTypeValues: [],
                installationTowerFaucetsValues: [],
                installationTowerFaucetsCustomValues: [],
                installationDripTrays: [],
                installationDripTraysCostPerTray: []
            }, () => this.setState({ installationTowers: res }));
        } else {
            console.log("onInstallationNumberOfTowersChange items 1",
                this.state.installationTowerValues,
                this.state.installationTowerTypeValues,
                this.state.installationTowerFaucetsValues,
                this.state.installationTowerFaucetsCustomValues);
            let arr_tv = this.getNumArray(p_value, this.state.installationTowerValues);
            let arr_tt = this.getTextArray(p_value, this.state.installationTowerTypeValues);
            let arr_fv = this.getNumArray(p_value, this.state.installationTowerFaucetsValues);
            let arr_cv = this.getNumArray(p_value, this.state.installationTowerFaucetsCustomValues);
            let arr_dt = this.getBoolArray(p_value, this.state.installationDripTrays);
            let arr_dc = this.getNumArray(p_value, this.state.installationDripTraysCostPerTray);

            console.log("onInstallationNumberOfTowersChange items 2", arr_tv, arr_tt, arr_fv, arr_cv);
            this.setState({
                installationNumberOfTowers: p_value,
                installationTowerValues: arr_tv,
                installationTowerTypeValues: arr_tt,
                installationTowerFaucetsValues: arr_fv,
                installationTowerFaucetsCustomValues: arr_cv,
                installationDripTrays: arr_dt,
                installationDripTraysCostPerTray: arr_dc

            }, () => {
                for (let i = 0; i < value; i++) {
                    let item = this.getInstallationTowersSelectItem(i);
                    res.push(item);
                }
                this.setState({ installationTowers: [] }, () => this.setState({ installationTowers: res }));
            });
        }

    }

    getInstallationTowersSelectItem = (p_index) => {
        return (
            <Row className="row-data" key={`row_installation_towers_${p_index}`}>
                <Col span={rowHeaderWidth} className="col-sub-header" >Tower {1 === this.state.installationNumberOfTowers ? '' : p_index + 1}</Col>
                <Col span={rowDataWidth} className="col-data">
                    {this.getInstallationTowersSelect(p_index)}
                </Col>
            </Row>
        );
    }

    updateInstallationTowers = (p_index) => {
        let items = this.state.installationTowers.slice(0);
        items[p_index] = this.getInstallationTowersSelectItem(p_index);
        this.setState({ installationTowers: items });
        //this.setState({ installationTowers: [] }, () => this.setState({ installationTowers: items }));
    }

    onInstallationTowerTypeChange = (p_value, p_index) => {
        console.log("onInstallationTowerTypeChange", p_value, p_index);

        let values = this.state.installationTowerTypeValues.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTowerTypeValues: values }, () => {
            this.updateInstallationTowers(p_index);
        });
    }

    onInstallationTowerFaucetsChange = (p_value, p_index) => {
        console.log("onInstallationTowerFaucetsChange", p_value, p_index, this.state.installationTowerFaucetsValues);

        let values = this.state.installationTowerFaucetsValues.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTowerFaucetsValues: values }, () => {
            this.updateInstallationTowers(p_index);
        });
    }

    onInstallationTowerFaucetsCustomChange = (p_value, p_index) => {
        console.log("onInstallationTowerFaucetsCustomChange", p_value, p_index, this.state.installationTowerFaucetsCustomValues);

        let values = this.state.installationTowerFaucetsCustomValues.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTowerFaucetsCustomValues: values }, () => {
            this.updateInstallationTowers(p_index);
        });
    }

    onInstallationDripTraysChange = (p_value, p_index) => {
        console.log("onInstallationDripTraysChange", p_value, p_index, this.state.installationDripTrays);

        let values = this.state.installationDripTrays.slice(0);
        values[p_index] = p_value;

        this.setState({ installationDripTrays: values }, () => {
            this.updateInstallationTowers(p_index);
        });
    }

    onInstallationDripTraysCostPerTrayChange = (p_value, p_index) => {
        console.log("onInstallationDripTraysCostPerTrayChange", p_value, p_index, this.state.installationDripTraysCostPerTray);

        let values = this.state.installationDripTraysCostPerTray.slice(0);
        values[p_index] = p_value;

        this.setState({ installationDripTraysCostPerTray: values }, () => {
            this.updateInstallationTowers(p_index);
        });
    }

    onInstallationNumberOfTrunklinesChange = (p_value) => {

        console.log("onInstallationNumberOfTrunklinesChange installationTrunklines", this.state.installationTrunklines);
        let value = 0;
        let res = [];
        if (Number.isInteger(p_value)) {
            value = Number(p_value);
        }
        if (0 === value) {
            res = [];
            this.setState({
                installationNumberOfTrunklines: p_value,
                installationTrunklines: [],
                installationTrunklineTypes: [],
                installationTrunklineYs: [],
                installationTrunklineManifolds: [],
                installationTrunklineManifoldsQuantity: []
            }, () => this.setState({ installationTrunklines: res }));
        } else {
            let new_arr = this.getNumArray(p_value, this.state.installationTrunklineValues);
            let new_tt = this.getTextArray(p_value, this.state.installationTrunklineTypes);
            let new_ys = this.getBoolArray(p_value, this.state.installationTrunklineYs);
            let new_mf = this.getBoolArray(p_value, this.state.installationTrunklineManifolds);
            let new_mq = this.getNumArray(p_value, this.state.installationTrunklineManifoldsQuantity);

            if (p_value === 1) {
                new_ys = this.getBoolArray(p_value, []);
            }
            if (p_value < 3) {
                new_mf = this.getBoolArray(p_value, []);
                new_mq = this.getNumArray(p_value, []);
            }

            this.setState({
                installationNumberOfTrunklines: p_value,
                installationTrunklineValues: new_arr,
                installationTrunklineTypes: new_tt,
                installationTrunklineYs: new_ys,
                installationTrunklineManifolds: new_mf,
                installationTrunklineManifoldsQuantity: new_mq
            }, () => {
                for (let i = 0; i < value; i++) {
                    let item = this.getInstallationTrunklinesSelectItem(i);
                    res.push(item);
                }
                this.setState({ installationTrunklines: [] }, () => this.setState({ installationTrunklines: res }));
            });
        }
    }

    getInstallationTrunklinesSelectItem = (p_index) => {
        return (
            <Row className="row-data" key={`row_installationTrunklines_${p_index}`}>
                <Col span={rowHeaderWidth} className="col-sub-header" >Trunkline {1 === this.state.installationNumberOfTrunklines ? '' : p_index + 1}</Col>
                <Col span={rowDataWidth} className="col-data">
                    {this.getInstallationTrunklinesSelect(p_index)}
                </Col>
            </Row>
        );

    }

    updateInstallationTrunklines = (p_index) => {
        let items = this.state.installationTrunklines.slice(0);
        items[p_index] = this.getInstallationTrunklinesSelectItem(p_index);
        this.setState({ installationTrunklines: items });
        //this.setState({ installationTrunklines: [] }, () => this.setState({ installationTrunklines: items }));
    }

    onInstallationTrunklineChange = (p_value, p_index) => {
        console.log("onInstallationTrunklineChange", p_value, p_index);

        let values = this.state.installationTrunklineValues.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTrunklineValues: values }, () => {
            this.updateInstallationTrunklines(p_index);
        });

    }

    onInstallationTrunklineTypeChange = (p_value, p_index) => {
        console.log("onInstallationTrunklineTypeChange", p_value, p_index);

        let values = this.state.installationTrunklineTypes.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTrunklineTypes: values }, () => {
            this.updateInstallationTrunklines(p_index);
        });
    }

    onInstallationTrunklineYsChange = (p_value, p_index) => {
        console.log("onInstallationTrunklineYsChange", p_value, p_index, this.state.installationTrunklineYs);

        let values = this.state.installationTrunklineYs.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTrunklineYs: values }, () => {
            this.updateInstallationTrunklines(p_index);
        });
    }

    onInstallationTrunklineManifoldsChange = (p_value, p_index) => {
        console.log("onInstallationTrunklineManifoldsChange", p_value, p_index, this.state.installationTrunklineManifolds);

        let values = this.state.installationTrunklineManifolds.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTrunklineManifolds: values }, () => {
            this.updateInstallationTrunklines(p_index);
        });
    }

    onInstallationTrunklineManifoldsQuantityChange = (p_value, p_index) => {
        console.log("onInstallationTrunklineManifoldsQuantityChange", p_value, p_index, this.state.installationTrunklineManifoldsQuantity);

        let values = this.state.installationTrunklineManifoldsQuantity.slice(0);
        values[p_index] = p_value;

        this.setState({ installationTrunklineManifoldsQuantity: values }, () => {
            this.updateInstallationTrunklines(p_index);
        });
    }

    onInstallationNumberOfGlycolUnitsChange = (p_value) => {

        console.log("onInstallationNumberOfGlycolUnitsChange installationGlycolUnits", this.state.installationGlycolUnits);
        let value = 0;
        let res = [];
        if (Number.isInteger(p_value)) {
            value = Number(p_value);
        }
        if (0 === value) {
            res = [];
            this.setState({ installationNumberOfGlycolUnits: p_value, installationGlycolUnits: [] }, () => this.setState({ installationGlycolUnits: res }));
        } else {
            let new_arr = this.getTextArray(p_value, this.state.installationGlycolUnitValues);
            this.setState({ installationGlycolUnitValues: new_arr }, () => {
                for (let i = 0; i < value; i++) {
                    let item =
                        <Row className="row-data" key={`row_installationGlycolUnits_${i}`}>
                            <Col span={rowHeaderWidth} className="col-sub-header" >Glycol unit {1 === value ? '' : i + 1}</Col>
                            <Col span={rowDataWidth} className="col-data">
                                {this.getInstallationGlycolUnitSelect(i)}
                            </Col>
                        </Row>
                    res.push(item);
                }
                this.setState({ installationNumberOfGlycolUnits: p_value, installationGlycolUnits: [] }, () => this.setState({ installationGlycolUnits: res }));
            });
        }
    }

    onInstallationGlycolUnitChange = (p_value, p_index) => {
        console.log("onInstallationGlycolUnitChange", p_value, p_index);

        let values = this.state.installationGlycolUnitValues.slice(0);
        values[p_index] = p_value;

        this.setState({ installationGlycolUnitValues: values }, () => {

            let items = this.state.installationGlycolUnits.slice(0);
            let item =
                <Row className="row-data" key={`row_installationGlycolUnits_${p_index}`}>
                    <Col span={rowHeaderWidth} className="col-sub-header" >Glycol unit {1 === this.state.installationNumberOfGlycolUnits ? '' : p_index + 1}</Col>
                    <Col span={rowDataWidth} className="col-data">
                        {this.getInstallationGlycolUnitSelect(p_index)}
                    </Col>
                </Row>
            items[p_index] = item;

            this.setState({ installationGlycolUnits: [] }, () => this.setState({ installationGlycolUnits: items }));
        });

    }


    onInstallationIsLacingKitChange = (checked) => {
        this.setState({ installationIsLacingKit: checked })
    }

    onInstallationIsAirSourceChange = (checked) => {
        //this.setState({ installationIsAirSource: checked })
    }

    onInstallationIsBlenderBoxChange = (checked) => {
        this.setState({ installationIsBlenderBox: checked })
    }

    onInstallationBlenderBoxPrimaryCo2RegulatorChange = (checked) => {
        let other_value = this.state.installationBlenderBoxPrimaryN2Regulator;
        if (!checked) other_value = true;
        this.setState({ installationBlenderBoxPrimaryCo2Regulator: checked, installationBlenderBoxPrimaryN2Regulator: other_value })
    }

    onInstallationBlenderBoxPrimaryN2RegulatorChange = (checked) => {
        let other_value = this.state.installationBlenderBoxPrimaryCo2Regulator;
        if (!checked) other_value = true;
        this.setState({ installationBlenderBoxPrimaryN2Regulator: checked, installationBlenderBoxPrimaryCo2Regulator: other_value })
    }

    onInstallationBlenderBoxPrimaryCo2RegulatorQtyChange = (value) => {
        this.setState({ installationBlenderBoxPrimaryCo2RegulatorQty: value })
    }

    onInstallationBlenderBoxPrimaryN2RegulatorQtyChange = (value) => {
        this.setState({ installationBlenderBoxPrimaryN2RegulatorQty: value })
    }

    onInstallationBlenderBoxPrimaryRegulatorChange = (e) => {
        console.log("onInstallationBlenderBoxPrimaryRegulatorChange", e.target.value, this.state.installationBlenderBoxPrimaryRegulator);
        this.setState({
            installationBlenderBoxPrimaryRegulator: e.target.value
        });
    }

    onInstallationBlenderBoxPrimaryRegulatorQtyChange = (value) => {
        this.setState({ installationBlenderBoxPrimaryRegulatorQty: value })
    }

    onInstallationIsDoubleportLeakDetectorChange = (checked) => {
        this.setState({ installationIsDoubleportLeakDetector: checked })
    }

    onInstallationIsSingleportLeakDetectorChange = (checked) => {
        this.setState({ installationIsSingleportLeakDetector: checked })
    }

    onInstallationLacingKitChange = (value) => {
        this.setState({ installationLacingKit: value });
    }

    onInstallationBlenderBlocksChange = (p_value) => {
        this.setState({ installationBlenderBlocks: p_value });
    }

    onInstallationDoubleportLeakDetectorChange = (p_value) => {
        this.setState({ installationDoubleportLeakDetector: p_value });
    }

    onInstallationSingleportLeakDetectorChange = (p_value) => {
        this.setState({ installationSingleportLeakDetector: p_value });
    }

    onInstallationWalkinPanelsTypeChange = (p_value) => {
        this.setState({ installationWalkinPanelsType: p_value });
    }

    onInstallationWalkinPanelsQuantityChange = (value) => {
        this.setState({ installationWalkinPanelsQuantity: value });
    }

    onInstallationWalkinPanelsPpSrpWFabChange = (checked) => {
        this.setState({ installationWalkinPanelsPpSrpWFab: checked });
    }

    onInstallationWalkinPanelsPpSrpWFabQtyChange = (value) => {
        this.setState({ installationWalkinPanelsPpSrpWFabQty: value });
    }

    onInstallationWalkinPanelsPpSrpNoFabsChange = (checked) => {
        this.setState({ installationWalkinPanelsPpSrpNoFabs: checked });
    }

    onInstallationWalkinPanelsPpSrpNoFabsQtyChange = (value) => {
        this.setState({ installationWalkinPanelsPpSrpNoFabsQty: value });
    }

    onInstallationGlycolJumperLineChange = (value) => {
        this.setState({ installationGlycolJumperLine: value });
    }

    onInstallationLaborChange = (value) => {
        this.setState({ installationLabor: value });
    }

    //#endregion

    isPrevButtonEnabled = () => {
        if (this.state.step === 0) return false;

        return true
    }

    isNextButtonEnabled = () => {
        let currentStep = this.getCurrentStepKey();
        if (currentStep === "done") {
            return false;
        }
        return true;
    }

    pageNext = () => {
        let step = this.state.step + 1;
        if (step > this.state.steps.length - 1) step = this.state.steps.length - 1;
        this.setState({ step: step });
    }

    pagePrev = () => {
        let step = this.state.step - 1;
        if (step < 0) step = 0;
        this.setState({ step: step });
    }

    getCurrentStepKey = () => {
        let result = 'settings';
        if (this.state.steps[this.state.step]) {
            result = this.state.steps[this.state.step].key;
        }
        return result;
    }

    //#region Settings
    renderMisc = () => {
        return (
            <div>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Location surcharge</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            step={1}
                            style={{ width: 120, float: 'left', marginRight: 10 }}
                            value={this.state.miscLocationSurcharge}
                            onChange={this.onMiscLocationSurchargeChange} /> (miles over 25)
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Start up and site survey</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            step={1}
                            style={{ width: 120, float: 'left', marginRight: 10 }}
                            value={this.state.miscStartUpAndSiteSurvey}
                            onChange={this.onMiscStartUpAndSiteSurveyChange} /> (hours)
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Discounting</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            max={40}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            style={{ width: 120, float: 'left', marginRight: 10 }}
                            value={this.state.miscDiscounting}
                            onChange={this.onMiscDiscountingChange} /> (max 40%)
                    </Col>
                </Row>
                <Row className="row-data">
                    <Col span={rowHeaderWidth} className="col-header">Uptick</Col>
                    <Col span={rowDataWidth} style={{ textAlign: 'left' }}>
                        <InputNumber
                            min={0}
                            max={25}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')}
                            style={{ width: 120, float: 'left', marginRight: 10 }}
                            value={this.state.miscUptick}
                            onChange={this.onMiscUptickChange} /> (max 25%)
                    </Col>
                </Row>
            </div>
        );
    }

    onMiscLocationSurchargeChange = (value) => {
        this.setState({ miscLocationSurcharge: value });
    }
    onMiscStartUpAndSiteSurveyChange = (value) => {
        this.setState({ miscStartUpAndSiteSurvey: value });
    }
    onMiscDiscountingChange = (value) => {
        this.setState({ miscDiscounting: value, miscUptick: 0 });
    }
    onMiscUptickChange = (value) => {
        this.setState({ miscUptick: value, miscDiscounting: 0 });
    }
    //#endregion

    //#region Done
    renderDone = () => {
        return (
            <div>
                <Row className="row-data">
                    <Button type="primary" style={{ width: 200 }} onClick={() => this.onShowCalculate(false)}>Calculate</Button>
                </Row>
                <Row className="row-data">
                    <Button type="primary" style={{ width: 200 }} onClick={this.onSend}>Send</Button>
                </Row>
            </div>
        );
    }
    //#endregion

    renderCurrentStep = () => {
        let currentStep = this.getCurrentStepKey();
        console.log("renderCurrentStep", currentStep);
        switch (currentStep) {
            case 'settings':
                return this.renderSettings();
            case 'customer':
                return this.renderCustomer();
            case 'contractor':
                return this.renderContractor();
            case 'cleaning':
                return this.renderCleaning();
            case 'installation':
                return this.renderInstallation();
            case 'misc':
                return this.renderMisc();
            case 'done':
                return this.renderDone();
            default:
                return this.renderSettings();
        }
    }

    //#region Save-Load
    onModalOk = () => {

        this.setState({ isModalVisible: false });
    }

    onModalCancel = () => {
        this.setState({ isModalVisible: false });
    }

    onMenuNew = () => {
        this.props.history.push('/');
        window.location.reload();
    }

    onMenuSave = () => {

        if (this.state.customerId > 0) {
            // save quote
            let self = this;
            let data = {
                'customer_id': this.state.customerId,
                'service_address': this.state.settingsServiceAddress,
                'service_city': this.state.settingsServiceCity,
                'service_state': this.state.settingsServiceState,
                'service_zip': this.state.settingsServiceZip,
                'is_contractor': 0,
                'job_type': 0
            };
            if (this.state.settingsIsContractor) {

                data.is_contractor = 1;
                if (this.state.contractorId > 0) {
                    data.contractor_id = this.state.contractorId;
                }
            }
            if (this.state.settingsJobType === 'cleaning') {
                data.job_type = 0;
                data.job_data = JSON.stringify({
                    'towers': this.state.cleaningNumberOfTowers,
                    'hookups': this.state.cleaningNumberOfHookups,
                    'facuets': this.state.cleaningNumberOfFacuets
                });
            } else {
                data.job_type = 1;
                data.job_data = JSON.stringify({
                    'number_of_towers': this.state.installationNumberOfTowers,

                    'tower_values': this.state.installationTowerValues,
                    'tower_type_values': this.state.installationTowerTypeValues,
                    'tower_faucets_values': this.state.installationTowerFaucetsValues,
                    'tower_faucets_custom_values': this.state.installationTowerFaucetsCustomValues,
                    'drip_trays': this.state.installationDripTrays,
                    'drip_trays_cost_per_tray': this.state.installationDripTraysCostPerTray,

                    'is_lacing_kit': this.state.installationIsLacingKit,
                    'lacing_kit': this.state.installationLacingKit,

                    'number_of_trunklines': this.state.installationNumberOfTrunklines,
                    'trunkline_values': this.state.installationTrunklineValues,
                    'trunkline_types': this.state.installationTrunklineTypes,
                    'trunkline_ys': this.state.installationTrunklineYs,
                    'trunkline_manifolds': this.state.installationTrunklineManifolds,
                    'trunkline_manifolds_quantity': this.state.installationTrunklineManifoldsQuantity,

                    'number_of_glycol_units': this.state.installationNumberOfGlycolUnits,
                    'glycol_units': this.state.installationGlycolUnitValues,
                    'is_air_source': this.state.installationIsAirSource,

                    'is_blender_box': this.state.installationIsBlenderBox,
                    'blender_box_primary_co2_regulator': this.state.installationBlenderBoxPrimaryCo2Regulator,
                    'blender_box_primary_co2_regulator_qty': this.state.installationBlenderBoxPrimaryCo2RegulatorQty,
                    'blender_box_primary_n2_regulator': this.state.installationBlenderBoxPrimaryN2Regulator,
                    'blender_box_primary_n2_regulator_qty': this.state.installationBlenderBoxPrimaryN2RegulatorQty,
                    'blender_box_primary_regulator': this.state.installationBlenderBoxPrimaryRegulator,
                    'blender_box_primary_regulator_qty': this.state.installationBlenderBoxPrimaryRegulatorQty,

                    'is_doubleport_leak_detector': this.state.installationIsDoubleportLeakDetector,
                    'doubleport_leak_detector': this.state.installationDoubleportLeakDetector,
                    'is_singleport_leak_detector': this.state.installationIsSingleportLeakDetector,
                    'singleport_leak_detector': this.state.installationSingleportLeakDetector,

                    //'walkin_panels_type': this.state.installationWalkinPanelsType,
                    //'walkin_panels_quantity': this.state.installationWalkinPanelsQuantity,

                    'walkin_panels_pp_srp_w_fab': this.state.installationWalkinPanelsPpSrpWFab,
                    'walkin_panels_pp_srp_w_fab_qty': this.state.installationWalkinPanelsPpSrpWFabQty,

                    'walkin_panels_pp_srp_no_fabs': this.state.installationWalkinPanelsPpSrpNoFabs,
                    'walkin_panels_pp_srp_no_fabs_qty': this.state.installationWalkinPanelsPpSrpNoFabsQty,

                    'glycol_jumper_line': this.state.installationGlycolJumperLine,
                    'labor': this.state.installationLabor
                });
            }
            data.job_misc = JSON.stringify({
                'location_surcharge': this.state.miscLocationSurcharge,
                'start_up_and_site_survey': this.state.miscStartUpAndSiteSurvey,
                'discounting': this.state.miscDiscounting,
                'uptick': this.state.miscUptick,
            });

            console.log("onMenuSave data", data);

            if (this.state.quoteId > 0) {
                // update quote
                console.log("onMenuSave update quote", data);
                gloDataPut(componentName, `${gloDataUrl}/quotes/${this.state.quoteId}?token=${this.state.token}`, data, this.onQuoteUpdated, 'Quote updated');
            } else {
                // create quote
                console.log("onMenuSave create quote", data);
                gloDataPostWithResult(componentName, `${gloDataUrl}/quotes?token=${this.state.token}`, data, this.onQuoteCreated, 'Quote created');
            }
        } else {
            message.error('Please select Customer');
        }
    }

    onQuoteUpdated = () => {
        //message.success('Quote updated');
    }

    onQuoteCreated = (p_data) => {
        //message.success('Quote created');
        console.log('onQuoteCreated p_data:', p_data);
        this.setState({ quoteId: p_data.data });

    }

    onMenuLoad = () => {
        gloDataGet(componentName, `${gloDataUrl}/quotes?token=${this.state.token}`, this.onQuotesIndexLoaded);
    }

    onQuotesIndexLoaded = (p_data) => {
        console.log('onQuotesIndexLoaded p_data:', p_data.data);
        this.setState({ modalQuotes: p_data.data, isModalVisible: true });

    }

    onModalQuoteSelect = (event, record) => {
        console.log("onModalQuoteSelect", record);

        let jobData = JSON.parse(record.job_data);
        let jobMisc = JSON.parse(record.job_misc);

        console.log("onModalQuoteSelect jobData", jobData);

        this.setState({
            quoteId: record.id,
            // load settings, customer and misc
            customerId: record.customer_id,
            customerName: record.customer_name,
            customerAddress: record.customer_address,
            customerCity: record.customer_city,
            customerState: record.customer_state,
            customerZip: record.customer_zip,
            customerEmail: record.customer_email,
            customerPhone: record.customer_phone,
            customerOwner: record.customer_owner,
            customerOwnerCell: record.customer_owner_cell,
            customerManager: record.customer_manager,
            customerManagerCell: record.customer_manager_cell,
            settingsServiceAddress: record.service_address,
            settingsServiceCity: record.service_city,
            settingsServiceState: record.service_state,
            settingsServiceZip: record.service_zip,
            settingsIsContractor: record.is_contractor === 1,
            settingsJobType: record.job_type ? "installation" : "cleaning",
            miscLocationSurcharge: jobMisc.location_surcharge,
            miscStartUpAndSiteSurvey: jobMisc.start_up_and_site_survey,
            miscDiscounting: jobMisc.discounting,
            miscUptick: jobMisc.uptick
        }, () => {
            //console.log("onModalQuoteSelect state 1", this.state)
            this.getSteps()
        });
        // load contractor
        if (record.is_contractor) {
            this.setState({
                contractorId: record.contractor_id,
                contractorName: record.contractor_name,
                contractorContact: record.contractor_contact,
                contractorPhone: record.contractor_phone,
                contractorEmail: record.contractor_email
            });
        }
        if (Number(record.job_type) === 1) {
            // load installation
            console.log("onModalQuoteSelect state load installation");
            this.setState({
                installationNumberOfTowers: jobData.number_of_towers,

                installationTowerValues: this.getNumArray(jobData.number_of_towers, jobData.tower_values),
                installationTowerTypeValues: this.getTextArray(jobData.number_of_towers, jobData.tower_type_values),
                installationTowerFaucetsValues: this.getNumArray(jobData.number_of_towers, jobData.tower_faucets_values),
                installationTowerFaucetsCustomValues: this.getNumArray(jobData.number_of_towers, jobData.tower_faucets_custom_values),
                installationDripTrays: this.getBoolArray(jobData.number_of_towers, jobData.drip_trays),
                installationDripTraysCostPerTray: this.getNumArray(jobData.number_of_towers, jobData.drip_trays_cost_per_tray),

                installationIsLacingKit: jobData.is_lacing_kit,
                installationLacingKit: jobData.lacing_kit,

                installationNumberOfTrunklines: jobData.number_of_trunklines,
                installationTrunklineValues: this.getNumArray(jobData.number_of_trunklines, jobData.trunkline_values),
                installationTrunklineTypes: this.getTextArray(jobData.number_of_trunklines, jobData.trunkline_types),
                installationTrunklineYs: this.getBoolArray(jobData.number_of_trunklines, jobData.trunkline_ys),
                installationTrunklineManifolds: this.getBoolArray(jobData.number_of_trunklines, jobData.trunkline_manifolds),
                installationTrunklineManifoldsQuantity: this.getNumArray(jobData.number_of_trunklines, jobData.trunkline_manifolds_quantity),


                installationNumberOfGlycolUnits: jobData.number_of_glycol_units,
                installationGlycolUnitValues: jobData.glycol_units,
                //installationIsAirSource: jobData.is_air_source,
                installationIsBlenderBox: jobData.is_blender_box,

                installationBlenderBoxPrimaryCo2Regulator: jobData.blender_box_primary_co2_regulator,
                installationBlenderBoxPrimaryCo2RegulatorQty: jobData.blender_box_primary_co2_regulator_qty,
                installationBlenderBoxPrimaryN2Regulator: jobData.blender_box_primary_n2_regulator,
                installationBlenderBoxPrimaryN2RegulatorQty: jobData.blender_box_primary_n2_regulator_qty,
                installationBlenderBoxPrimaryRegulator: jobData.blender_box_primary_regulator,
                installationBlenderBoxPrimaryRegulatorQty: jobData.blender_box_primary_regulator_qty,


                //installationBlenderBlocks: jobData.blender_blocks,
                installationIsDoubleportLeakDetector: jobData.is_doubleport_leak_detector,
                installationDoubleportLeakDetector: jobData.doubleport_leak_detector,
                installationIsSingleportLeakDetector: jobData.is_singleport_leak_detector,
                installationSingleportLeakDetector: jobData.singleport_leak_detector,

                //installationWalkinPanelsType: jobData.walkin_panels_type,
                //installationWalkinPanelsQuantity: jobData.walkin_panels_quantity,

                installationWalkinPanelsPpSrpWFab: jobData.walkin_panels_pp_srp_w_fab,
                installationWalkinPanelsPpSrpWFabQty: jobData.walkin_panels_pp_srp_w_fab_qty,

                installationWalkinPanelsPpSrpNoFabs: jobData.walkin_panels_pp_srp_no_fabs,
                installationWalkinPanelsPpSrpNoFabsQty: jobData.walkin_panels_pp_srp_no_fabs_qty,

                installationGlycolJumperLine: jobData.glycol_jumper_line,
                installationLabor: jobData.labor,
            }, () => {
                this.onInstallationNumberOfTowersChange(jobData.number_of_towers);
                this.onInstallationNumberOfTrunklinesChange(jobData.number_of_trunklines);
                this.onInstallationNumberOfGlycolUnitsChange(jobData.number_of_glycol_units);
            });
        } else {
            // load cleaning
            this.setState({
                cleaningNumberOfTowers: jobData.towers,
                cleaningNumberOfHookups: jobData.hookups,
                cleaningNumberOfFacuets: jobData.facuets,
            });
        }
        this.setState({ isModalVisible: false });
    }
    //#endregion

    onSend = () => {
        console.log("onSend", gloRootUrl, this.state.quoteId);
        if (this.state.quoteId && this.state.quoteId > 0) {
            /*            
                        var textField = document.createElement('textarea');
                        textField.innerText = `${gloRootUrl}/?id=${this.state.quoteId}`;
                        document.body.appendChild(textField);
                        textField.select();
                        document.execCommand('copy');
                        textField.remove();
                        message.success('Link copied to clipboard');
            */
            //window.open(`mailto:ngs@das.to?subject=Quote #${this.state.quoteId}&body=${gloRootUrl}/?id=${this.state.quoteId}`);

//            let calc = ReactDOMServer.renderToString(this.onShowCalculate(true));
            if (this.state.customerName && this.state.customerName.length > 0) {
            let data = {
                'subject': this.state.customerName,
                'message': `${gloRootUrl}/?id=${this.state.quoteId}`,
                'link': `${gloRootUrl}/?id=${this.state.quoteId}`,
            };
            let url = `${gloDataUrl}/send?token=${this.state.token}`;
            gloDataPost(componentName, url, data, this.onSendCompleted, 'Message sent');
            } else {
                message.warning('Please set customer name');
            }
        } else {
            message.warning('Please save quote before send');
        }
    }

    onSendCompleted = () => {
        //message.success('Message sent');
    }

    onShowCalculate = (p_mail) => {
        let result = '';
        /* calculate Cleaning */
        /* Towers */
        let res_cleaning_towers = '';
        let res_cleaning_towers_money = 0;
        if (this.state.cleaningNumberOfTowers > 0) {
            if (this.state.cleaningNumberOfTowers > 4) {
                res_cleaning_towers_money = 95 + 10 * (this.state.cleaningNumberOfTowers - 4);
                res_cleaning_towers = <div>
                    <b>Towers</b>: $95.00 + $10.00 * {this.state.cleaningNumberOfTowers - 4} = {gloRenderPrice(res_cleaning_towers_money)}
                </div>
            } else {
                res_cleaning_towers_money = 95;
                res_cleaning_towers = <div>
                    <b>Towers</b>: $95.00
                </div>
            }
        }
        /* Hookups */
        let res_cleaning_hookups = '';
        let res_cleaning_hookups_money = 0;
        if (this.state.cleaningNumberOfHookups > 0) {
            if (this.state.cleaningNumberOfHookups > 4) {
                res_cleaning_hookups_money = 95 + 10 * (this.state.cleaningNumberOfHookups - 4);
                res_cleaning_hookups = <div>
                    <b>Hookups</b>: $95.00 + $10.00 * {this.state.cleaningNumberOfHookups - 4} = {gloRenderPrice(res_cleaning_hookups_money)}
                </div>
            } else {
                res_cleaning_hookups_money = 95;
                res_cleaning_hookups = <div>
                    <b>Hookups</b>: $95.00
                </div>
            }
        }
        /* Facuets */
        let res_cleaning_facuets = '';
        let res_cleaning_facuets_money = 0;
        if (this.state.cleaningNumberOfFacuets > 0) {
            if (this.state.cleaningNumberOfFacuets > 4) {
                res_cleaning_facuets_money = 95 + 10 * (this.state.cleaningNumberOfFacuets - 4);
                res_cleaning_facuets = <div>
                    <b>Faucets</b>: $95.00 + $10.00 * {this.state.cleaningNumberOfFacuets - 4} = {gloRenderPrice(res_cleaning_facuets_money)}
                </div>
            } else {
                res_cleaning_facuets_money = 95;
                res_cleaning_facuets = <div>
                    <b>Faucets</b>: $95.00
                </div>
            }
        }
        /* Cleaning total */
        let res_cleaning_money =
            res_cleaning_towers_money +
            res_cleaning_hookups_money +
            res_cleaning_facuets_money;
        let res_cleaning = <div>
            <div style={{ fontSize: 24, marginTop: 20 }}>Cleaning: {gloRenderPrice(res_cleaning_money)}</div>
            {res_cleaning_towers}
            {res_cleaning_hookups}
            {res_cleaning_facuets}
        </div>

        /* calculate Installation */
        /* Towers */
        let res_installation_towers = [];
        let res_installation_towers_money = 0;
        if (this.state.installationNumberOfTowers > 0) {
            for (let i = 0; i < this.state.installationNumberOfTowers; i++) {

                let tt = gloGetTitleFromValue(this.state.installationTowerTypeValues[i]);
                let tf = this.state.installationTowerFaucetsValues[i] > -1 ? this.state.installationTowerFaucetsValues[i] : this.state.installationTowerFaucetsCustomValues[i];
                let dt = this.state.installationDripTrays[i] ? 'Yes' : 'No';
                let tc = this.state.installationDripTraysCostPerTray[i];

                console.log("onShowCalculate calculate Installation Towers", tt, tf, dt, tc);

                res_installation_towers.push(<li key={i}><b>Type:</b> {tt} <b># faucets:</b> {tf} <b>Product Drip Trays:</b> {dt} <b>Cost:</b> {gloRenderPrice(tc)}</li>)
                res_installation_towers_money += Number(tc);
            }
            res_installation_towers = <div className="div-calculation-group">
                <b>Towers Type:</b>
                <ul>{res_installation_towers}</ul>
                <b>Total: </b>{gloRenderPrice(res_installation_towers_money)}
            </div>
        }

        /* Lacing kit */
        let res_installation_lacing_kit = '';
        let res_installation_lacing_kit_money = 0;
        if (this.state.installationIsLacingKit) {
            res_installation_lacing_kit_money = this.state.installationLacingKit;
            res_installation_lacing_kit = <div className="div-calculation-group">
                <b>Lacing kit</b>: {gloRenderPrice(res_installation_lacing_kit_money)}
            </div>
        }

        /* Trunklines */
        let res_installation_trunklines = [];
        let res_installation_trunklines_money = 0;
        console.log("onShowCalculate Trunklines ", this.state.installationNumberOfTrunklines);
        if (this.state.installationNumberOfTrunklines > 0) {
            for (let i = 0; i < this.state.installationNumberOfTrunklines; i++) {

                let tt = gloGetTrunklineTitleFromValue(this.state.installationTrunklineTypes[i]);
                let tl = this.state.installationTrunklineValues[i];
                let tp = 0;
                if (this.state.installationTrunklineTypes[i] === 'crusader_2_4') {
                    tp = 11.50;
                } else if (this.state.installationTrunklineTypes[i] === 'crusader_2_6') {
                    tp = 13;
                } else if (this.state.installationTrunklineTypes[i] === 'crusader_2_8') {
                    tp = 15;
                } else if (this.state.installationTrunklineTypes[i] === 'crusader_2_10') {
                    tp = 17;
                } else if (this.state.installationTrunklineTypes[i] === 'crusader_4_10') {
                    tp = 17;
                }

                if (this.state.installationNumberOfTrunklines < 2) {

                    res_installation_trunklines.push(<li key={i}><b>Type:</b> {tt} <b>Length:</b> {tl} <b>Cost:</b> {gloRenderPrice(tp)} * {tl} = {gloRenderPrice(tp * tl)}</li>)
                    res_installation_trunklines_money += tp * tl;

                } else if (this.state.installationNumberOfTrunklines === 2) {
                    if (this.state.installationTrunklineYs[i]) {
                        res_installation_trunklines.push(<li key={i}><b>Type:</b> {tt} <b>Length:</b> {tl} <b>Ys:</b> Yes <b>Cost:</b> {gloRenderPrice(tp)} * {tl} + $5.00 = {gloRenderPrice(tp * tl + 5)}</li>)
                        res_installation_trunklines_money += tp * tl + 5;
                    } else {
                        res_installation_trunklines.push(<li key={i}><b>Type:</b> {tt} <b>Length:</b> {tl} <b>Ys:</b> No <b>Cost:</b> {gloRenderPrice(tp)} * {tl} = {gloRenderPrice(tp * tl)}</li>)
                        res_installation_trunklines_money += tp * tl;
                    }

                } else if (this.state.installationNumberOfTrunklines > 2) {

                    let t_ys = this.state.installationTrunklineYs[i] ? 'Yes' : 'No';
                    let t_mf = this.state.installationTrunklineManifolds[i] ? 'Yes' : 'No';
                    let t_mq = this.state.installationTrunklineManifoldsQuantity[i];
                    let t_co = tp * tl;
                    if (this.state.installationTrunklineYs[i]) t_co += 5;
                    if (this.state.installationTrunklineManifolds[i]) t_co += 60 * t_mq;

                    res_installation_trunklines.push(
                        <li key={i}>
                            <b>Type:</b> {tt}
                            <b style={{ marginLeft: 10 }}>Length:</b> {tl}
                            <b style={{ marginLeft: 10 }}>Ys:</b> {t_ys}
                            <b style={{ marginLeft: 10 }}>Manifolds:</b> {t_mf}
                            {this.state.installationTrunklineManifolds[i] ? <span style={{ marginLeft: 10 }}><b>Quantity:</b><span> {t_mq}</span></span> : ''}
                            <b style={{ marginLeft: 10 }}>Cost:</b> {gloRenderPrice(tp)} * {tl} {this.state.installationTrunklineYs[i] ? '+ $5.00' : ''} {this.state.installationTrunklineManifolds[i] ? `+ ($60.00 * ${t_mq})` : ''} = {gloRenderPrice(t_co)}</li>)
                    res_installation_trunklines_money += t_co;
                }
            }

            res_installation_trunklines = <div className="div-calculation-group">
                <b>Trunklines:</b>
                <ul>{res_installation_trunklines}</ul>
                <b>Total: </b>{gloRenderPrice(res_installation_trunklines_money)}
            </div>

        }

        /* Glycol units */
        let res_installation_glycol_units = [];
        let res_installation_glycol_units_money = 0;
        if (this.state.installationNumberOfGlycolUnits > 0) {
            for (let i = 0; i < this.state.installationNumberOfGlycolUnits; i++) {
                let tc = 0;
                let gt = gloGetGlycolUnitTitleFromValue(this.state.installationGlycolUnitValues[i]);
                if (this.state.installationGlycolUnitValues[i] === 'crusader_225') {
                    tc = 1900;
                } else if (this.state.installationGlycolUnitValues[i] === 'crusader_350') {
                    tc = 2250;
                } else if (this.state.installationGlycolUnitValues[i] === 'perlick_quickchill_1_3_hp') {
                    tc = 2250;
                } else if (this.state.installationGlycolUnitValues[i] === 'perlick_4404_1_2_hp') {
                    tc = 2500;
                }
                res_installation_glycol_units.push(<li key={i}><b>Type:</b> {gt} <b>Cost:</b> {gloRenderPrice(tc)}</li>);
                res_installation_glycol_units_money += tc
            }

            res_installation_glycol_units = <div className="div-calculation-group">
                <b>Glycol units:</b>
                <ul>{res_installation_glycol_units}</ul>
                <b>Total: </b>{gloRenderPrice(res_installation_glycol_units_money)}
            </div>
        }

        /* Air source */
        let res_installation_air_source = [];
        let res_installation_air_source_money = 0;
        if (this.state.installationIsBlenderBox) {
            let a_co = 0;
            if (this.state.installationBlenderBoxPrimaryCo2RegulatorQty > 0) {
                res_installation_air_source_money += this.state.installationBlenderBoxPrimaryCo2RegulatorQty * 110;
                res_installation_air_source.push(
                    <li key="primary_co2_regulator"><b>Primary Co2 Regulator:</b> $110.00 * {this.state.installationBlenderBoxPrimaryCo2RegulatorQty} = {gloRenderPrice(this.state.installationBlenderBoxPrimaryCo2RegulatorQty * 110)}</li>
                )
            }
            if (this.state.installationBlenderBoxPrimaryN2RegulatorQty > 0) {
                res_installation_air_source_money += this.state.installationBlenderBoxPrimaryN2RegulatorQty * 110;
                res_installation_air_source.push(
                    <li key="primary_n2_regulator2"><b>Primary N2 Regulator:</b> $110.00 * {this.state.installationBlenderBoxPrimaryN2RegulatorQty} = {gloRenderPrice(this.state.installationBlenderBoxPrimaryN2RegulatorQty * 110)}</li>
                )
            }
        } else {
            if (this.state.installationBlenderBoxPrimaryCo2Regulator) {
                res_installation_air_source_money += this.state.installationBlenderBoxPrimaryCo2RegulatorQty * 110;
                res_installation_air_source.push(
                    <li key="primary_co2_regulator"><b>Primary Co2 Regulator:</b> $110.00 * {this.state.installationBlenderBoxPrimaryCo2RegulatorQty} = {gloRenderPrice(this.state.installationBlenderBoxPrimaryCo2RegulatorQty * 110)}</li>
                )
            }
            if (this.state.installationBlenderBoxPrimaryN2Regulator) {
                res_installation_air_source_money += this.state.installationBlenderBoxPrimaryN2RegulatorQty * 110;
                res_installation_air_source.push(
                    <li key="primary_n2_regulator"><b>Primary N2 Regulator:</b> $110.00 * {this.state.installationBlenderBoxPrimaryN2RegulatorQty} = {gloRenderPrice(this.state.installationBlenderBoxPrimaryN2RegulatorQty * 110)}</li>
                )
            }
        }

        if (this.state.installationIsSingleportLeakDetector) {
            let dc = this.state.installationSingleportLeakDetector;
            res_installation_air_source_money += 165 * dc;
            res_installation_air_source.push(
                <li key="installationIsSingleportLeakDetector"><b>Singleport leak detector:</b> $165.00 * {dc} = {gloRenderPrice(165 * dc)}</li>
            )

        }

        if (this.state.installationIsDoubleportLeakDetector) {
            let dc = this.state.installationDoubleportLeakDetector;
            res_installation_air_source_money += 195 * dc;
            res_installation_air_source.push(
                <li key="installationIsDoubleportLeakDetector"><b>Doubleport leak detector:</b> $195.00 * {dc} = {gloRenderPrice(195 * dc)}</li>
            )
        }

        res_installation_air_source = <div className="div-calculation-group">
            <b>Air source:</b> 
            <ul>{res_installation_air_source}</ul>
            <b>Total: </b>{gloRenderPrice(res_installation_air_source_money)}
        </div>

        /* Walk in Panels */
        let res_installation_walk_in_panels = [];
        let res_installation_walk_in_panels_money = 0;

        //if (this.state.installationWalkinPanelsQuantity > 0) {

        if (this.state.installationWalkinPanelsPpSrpWFab) {
            let qty = this.state.installationWalkinPanelsPpSrpWFabQty;
            res_installation_walk_in_panels_money += 190 * qty;
            res_installation_walk_in_panels.push(
                <li key="installation_walk_in_panels_1"><b>Pp Srp W FOB:</b> $190.00 * {qty} = {gloRenderPrice(190 * qty)}</li>
            )
        }

        if (this.state.installationWalkinPanelsPpSrpNoFabs) {
            let qty = this.state.installationWalkinPanelsPpSrpNoFabsQty;
            res_installation_walk_in_panels_money += 125 * qty;
            res_installation_walk_in_panels.push(
                <li key="installation_walk_in_panels_2"><b>Pp Srp No FOB's:</b> $125.00 * {qty} = {gloRenderPrice(125 * qty)}</li>
            )
        }

        if (res_installation_walk_in_panels_money > 0) {
            res_installation_walk_in_panels = <div className="div-calculation-group">
                <b>Walk in Panels:</b> 
                <ul>{res_installation_walk_in_panels}</ul>
                <b>Total: </b>{gloRenderPrice(res_installation_walk_in_panels_money)}
            </div>
        }

        /* Glycol Jumper line */
        let res_installation_glycol_jumper_line = '';
        let res_installation_glycol_jumper_line_money = 0;

        if (this.state.installationGlycolJumperLine > 0) {
            res_installation_glycol_jumper_line_money = this.state.installationGlycolJumperLine * 10;
            res_installation_glycol_jumper_line = <div className="div-calculation-group"><b>Glycol Jumper line</b>: {this.state.installationGlycolJumperLine} * $10.00 = {gloRenderPrice(res_installation_glycol_jumper_line_money)}</div>
        }

        /* Equipment total */
        let res_equipment_total_money =
            res_installation_towers_money +
            //res_installation_drip_trays_money +
            res_installation_lacing_kit_money +
            res_installation_trunklines_money +
            res_installation_glycol_units_money +
            res_installation_air_source_money +
            res_installation_walk_in_panels_money +
            res_installation_glycol_jumper_line_money;
        /*
                    {res_installation_drip_trays}
        */
        let res_installation_equipment_total = "";
        if (res_equipment_total_money > 0) {
            res_installation_equipment_total = <div className="div-calculation-group-total"><b>Equipment total</b>: {gloRenderPrice(res_equipment_total_money)}</div>
        }        

        /* Labor */
        let res_installation_labor = '';
        let res_installation_labor_money = 0;
        if (this.state.installationLabor > 0) {
            if (this.state.installationLabor * 2240 < 2750) {
                res_installation_labor_money = 2750;
                res_installation_labor = <div className="div-calculation-group"><b>Labor to install</b>: {gloRenderPrice(2750)}</div>

            } else {
                res_installation_labor_money = 2240 * this.state.installationLabor;
                res_installation_labor = <div className="div-calculation-group"><b>Labor to install</b>: {gloRenderPrice(2240)} * {this.state.installationLabor} = {gloRenderPrice(res_installation_labor_money)}</div>
            }
        }
        /* Installation total */
        let res_installation_money =
            res_installation_towers_money +
            //res_installation_drip_trays_money +
            res_installation_lacing_kit_money +
            res_installation_trunklines_money +
            res_installation_glycol_units_money +
            res_installation_air_source_money +
            res_installation_walk_in_panels_money +
            res_installation_glycol_jumper_line_money +
            res_installation_labor_money;
        /*
                    {res_installation_drip_trays}
        */

        /* Start up and site survey */
        let res_misc_start_up_and_site_survey_money = 0;
        let res_misc_start_up_and_site_survey = '';
        if (this.state.miscStartUpAndSiteSurvey > 0) {
            res_misc_start_up_and_site_survey_money = this.state.miscStartUpAndSiteSurvey * 280;
            res_misc_start_up_and_site_survey = <div className="div-calculation-group"><b>Start up and site survey</b>: {this.state.miscStartUpAndSiteSurvey} * $280.00 = {gloRenderPrice(res_misc_start_up_and_site_survey_money)}</div>
        }

        let res_installation_grand_total_for_labor = '';
        if (res_misc_start_up_and_site_survey_money > 0 && res_installation_labor_money > 0) {
            res_installation_grand_total_for_labor = <div className="div-calculation-group-total"><b>Grand total for labor</b>: {gloRenderPrice(res_misc_start_up_and_site_survey_money + res_installation_labor_money)}</div>
        }

        let res_installation = <div>
            <div style={{ fontSize: 24, marginTop: 20 }}>Installation: {gloRenderPrice(res_installation_money)}</div>
            {res_installation_towers}
            {res_installation_lacing_kit}
            {res_installation_trunklines}
            {res_installation_glycol_units}
            {res_installation_air_source}
            {res_installation_walk_in_panels}
            {res_installation_glycol_jumper_line}
            {res_installation_equipment_total}
            {res_installation_labor}
            {res_misc_start_up_and_site_survey}
            {res_installation_grand_total_for_labor}
            <hr />
        </div>

        /* calculate Misc */
        /* Location surcharge */
        let res_misc_location_surcharge_money = 0;
        let res_misc_location_surcharge = '';
        if (this.state.miscLocationSurcharge > 0) {
            res_misc_location_surcharge_money = this.state.miscLocationSurcharge * 2.5;
            res_misc_location_surcharge = <div className="div-calculation-group"><b>Location surcharge</b>: {this.state.miscLocationSurcharge} * $2.50 = {gloRenderPrice(res_misc_location_surcharge_money)}</div>
        }

        let res_misc_money = res_misc_location_surcharge_money + res_misc_start_up_and_site_survey_money;

        let res_total_money = (this.state.settingsJobType === 'cleaning' ? res_cleaning_money : res_installation_money) + res_misc_money;


        /* Discounting */
        let res_misc_discounting_money = 0;
        let res_misc_discounting = '';
        if (this.state.miscDiscounting > 0) {
            res_misc_discounting_money = Math.round((res_total_money * (this.state.miscDiscounting / 100)) * 100) / 100;
            res_misc_discounting = <div className="div-calculation-group"><b>Discounting</b>: {gloRenderPrice(res_total_money)} * {this.state.miscDiscounting}% = {gloRenderPrice(res_misc_discounting_money)}</div>
        }
        /* Uptick */
        let res_misc_uptick_money = 0;
        let res_misc_uptick = '';
        if (this.state.miscUptick > 0) {
            res_misc_uptick_money = Math.round((res_total_money * (this.state.miscUptick / 100)) * 100) / 100;
            res_misc_uptick = <div className="div-calculation-group"><b>Uptick</b>: {gloRenderPrice(res_total_money)} * {this.state.miscUptick}% = {gloRenderPrice(res_misc_uptick_money)}</div>
        }

        /* Misc total */
        let res_misc = (res_misc_location_surcharge !== '') || (res_misc_start_up_and_site_survey !== '')
            ? <div>
                <div style={{ fontSize: 24, marginTop: 20 }}>Misc: {gloRenderPrice(res_misc_money)}</div>
                {res_misc_location_surcharge}
                {res_misc_start_up_and_site_survey}
                <hr />
            </div>
            : ''

        result = <div style={{ padding: 20, textAlign: 'left' }}>
            {this.state.settingsJobType === 'cleaning'
                ? res_cleaning
                : res_installation
            }
            {res_misc}
            {res_misc_discounting !== '' || res_misc_uptick !== ''
                ? <div>
                    <div style={{ fontSize: 24, marginTop: 20 }}>Other</div>
                    {res_misc_discounting}
                    {res_misc_uptick}
                    <hr />
                </div>
                : ''
            }
            <div><b>Total</b>: {gloRenderPrice(res_total_money)} - {gloRenderPrice(res_misc_discounting_money)} discount + {gloRenderPrice(res_misc_uptick_money)} uptick = {gloRenderPrice(Math.round((res_total_money - res_misc_discounting_money + res_misc_uptick_money) * 100) / 100)} </div>
        </div>
        if (p_mail) {
            return result;
        } else {
            this.setState({ calculateResult: result, isShowCalculate: true });
        }
    }

    onHideCalculate = () => {
        this.setState({ isShowCalculate: false });
    }

    onShowResults = () => {
        this.setState({ isShowResult: true });
    }

    onHideResults = () => {
        this.setState({ isShowResult: false });
    }

    onDownloadResults = () => {

        let page = ReactDOMServer.renderToStaticMarkup(this.renderResults()); // document.documentElement.outerHTML;

        console.log("onDownloadResults page", page);

        var element = document.createElement("a");
        var file = new Blob([page], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "export.html";
        element.click();

        this.setState({ isShowResult: false });

    }

    renderCalculate = () => {
        return <div>
            {/* close button */}
            {/*
            <Button icon="close" shape="circle" onClick={this.onHideCalculate} style={{ position: 'fixed', top: 10, right: 10 }} />
            */}
            {this.state.calculateResult}
        </div>
    }

    renderResults = () => {
        let res_installation_labor_money = 0;
        if (this.state.installationLabor > 0) {
            if (this.state.installationLabor * 2240 < 2750) {
                res_installation_labor_money = 2750;
            } else {
                res_installation_labor_money = 2240 * this.state.installationLabor;
            }
        }
        return <div style={{ minWidth: 900 }}>
            {/* close button */}
            {/* 
            <div style={{ position: 'fixed', top: 10, right: 10 }}>
                <Button icon="download" shape="circle" onClick={this.onDownloadResults} style={{ marginRight: 10 }} />
                <Button icon="close" shape="circle" onClick={this.onHideResults} />
            </div>
            */}
            {/* header */}
            <div style={{ textAlign: 'left', paddingLeft: 40 }}>
                <div style={{ display: 'inline-block', float: 'left' }}><img style={{ width: 100 }} src={`${root_path}/images/logo2.png`} /></div>
                <div style={{ display: 'inline-block', width: 400, paddingLeft: 20 }}>
                    <div style={{ fontSize: 30, fontWeight: 'bold', textAlign: 'left', lineHeight: 1, marginBottom: 5, marginTop: 20 }}>
                        Perfect Pour Draft Beer Specialist
                    </div>
                    <div style={{ width: 400, textAlign: 'left', lineHeight: 1 }}>
                        <p>81 Pondfield Road #171 | Bronxwille, NY 10708<br />
                            914-356-7311 | contact@perfectpourdraft.com</p>
                    </div>
                </div>
                {/* estimate */}
                <div style={{ display: 'inline-block', float: 'right', textAlign: 'left', right: 10, top: 150, width: 300 }}>
                    <div style={{ backgroundColor: 'black', color: 'white', padding: 10, fontSize: 20 }}>Estimate #{this.state.quoteId}</div>
                    <div style={{ padding: 10, backgroundColor: 'lightgray' }}>Sent on</div>
                    <div style={{ backgroundColor: 'black', color: 'white', padding: 10, fontSize: 18 }}>Total
                    <div style={{ float: 'right' }}>$2,563.18</div>
                    </div>
                </div>

            </div>
            {/* recipient */}
            <div style={{ textAlign: 'left', paddingLeft: 40, marginTop: 20 }}>
                {/* name */}
                <div style={{ marginBottom: 15, fontSize: 12, fontWeight: 800 }}>RECIPIENT:</div>
                <div style={{ fontSize: 20, fontWeight: 'bold' }}>{this.state.customerName}</div>
                <div>
                    <p>
                        {this.state.customerAddress}<br />
                        {this.state.customerCity}, {this.state.customerState} {this.state.customerZip}
                    </p>
                </div>
                {/* address */}
                <div style={{ marginBottom: 15, fontSize: 12, fontWeight: 800 }}>SERVICE ADDRESS:</div>
                <div>
                    <p>
                        {this.state.settingsServiceAddress}<br />
                        {this.state.settingsServiceCity}, {this.state.settingsServiceState} {this.state.settingsServiceZip}
                    </p>
                </div>
            </div>
            {/* quote */}
            <div style={{ marginLeft: 40, marginRight: 40 }}>
                <table style={{ width: '100%', textAlign: 'left' }}>
                    <tr style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}>
                        <td style={{ padding: 5 }}>SERVICE/PRODUCT</td>
                        <td style={{ padding: 5 }}>DESCRIPTION</td>
                        <td style={{ padding: 5 }}>QTY.</td>
                        <td style={{ padding: 5 }}>UNIT COST</td>
                        <td style={{ padding: 5 }}>TOTAL</td>
                    </tr>
                    <tr>
                        <td>Equipment</td>
                        <td>{ExportUtils.getEquipment(this.state)}</td>
                        <td>1</td>
                        <td>$1,370.00</td>
                        <td>$1,370.00</td>
                    </tr>
                    <tr>
                        <td>Labour</td>
                        <td></td>
                        <td>1</td>
                        <td>{this.state.settingsJobType === 'installation' ? gloRenderPrice(res_installation_labor_money) : '-'}</td>
                        <td>{this.state.settingsJobType === 'installation' ? gloRenderPrice(res_installation_labor_money) : '-'}</td>
                    </tr>
                    <tr>
                        <td>Shipping &amp; Handling</td>
                        <td>Estimated &amp; will be adjusted</td>
                        <td>1</td>
                        <td>$500.00</td>
                        <td>$500.00</td>
                    </tr>
                </table>
            </div>
            {/* total */}
            {/* signature */}
        </div>
    }

    render() {

        //console.log("### render state", this.state);

        const menu = (
            <Menu style={{ width: 100, textAlign: 'center', margin: 10 }}>
                <Menu.Item key="MenuNew" onClick={this.onMenuNew}>
                    <Icon type="file" /><span>New</span>
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item key="MenuSave" onClick={this.onMenuSave}>
                    <Icon type="save" /><span>Save</span>
                </Menu.Item>
                <Menu.Item key="MenuLoad" onClick={this.onMenuLoad} >
                    <Icon type="download" /><span>Load</span>
                </Menu.Item>
{/*
                <Menu.Divider />
                <Menu.Item key="MenuExport" onClick={this.onShowResults} >
                    <Icon type="export" /><span>Export</span>
                </Menu.Item>
*/}                                
            </Menu>
        );
        const columns = [
            {
                title: 'Customer', key: 'name', render: x => (
                    <div>
                        <div style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left', lineHeight: 1, marginBottom: 5, marginTop: 20 }}>
                            {x.customer_name}
                        </div>
                        <div>
                            <div className="row-id">#{x.id}</div>
                            <AddressText
                                title='RECEPIENT'
                                address={x.customer_address}
                                city={x.customer_city}
                                state={x.customer_state}
                                zip={x.customer_zip}
                                phone={x.customer_phone}
                                email={x.customer_email}
                            />
                            <AddressText
                                title='SERVICE ADDRESS'
                                address={x.service_address}
                                city={x.service_city}
                                state={x.service_state}
                                zip={x.service_zip}
                            />
                            <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                <div style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'left', lineHeight: 1, marginBottom: 5, marginTop: 5 }}>JOB</div>
                                <div style={{ textAlign: 'left', lineHeight: 1 }}>
                                    <p>{x.job_type === 'cleaning' ? 'Cleaning' : 'Installation'} | created: {x.created_at} | updated: {x.updated_at}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            },
        ];
        /*
                if (this.state.isShowCalculate) {
                    return this.renderCalculate();
                } else if (this.state.isShowResult) {
                    return this.renderResults();
                } else {
        */
        return (
            <Layout>
                <Header>
                    <div style={{ marginLeft: 20 }}>
                        <Steps labelPlacement="vertical" direction="horizontal" size="small" current={this.state.step}>
                            {this.state.steps}
                        </Steps>
                    </div>
                    <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000 }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <a className="ant-dropdown-link" href="#">
                                <Button type="primary" icon="menu-unfold" style={{ borderTopLeftRadius: 0, height: 64, borderBottomLeftRadius: 0 }} />
                            </a>
                        </Dropdown>
                    </div>
                </Header>
                <Layout className="main-content" >
                    <Content style={{ paddingTop: 20, overflow: 'scroll' }}>

                        {this.renderCurrentStep()}

                    </Content>
                </Layout>
                <Footer>
                    <Button.Group size='small'>
                        {this.isPrevButtonEnabled()
                            ? <Button type="primary" onClick={this.pagePrev}><Icon type="left" />Previous</Button>
                            : <Button type="primary" disabled onClick={this.pagePrev}><Icon type="left" />Previous</Button>
                        }
                        {this.state.page === 5
                            ? <Button type="primary" >Finish</Button>
                            : this.isNextButtonEnabled()
                                ? <Button type="primary" onClick={this.pageNext}>Next<Icon type="right" /></Button>
                                : <Button type="primary" disabled onClick={this.pageNext}>Next<Icon type="right" /></Button>
                        }
                    </Button.Group>
                </Footer>

                <Modal
                    title="Load saved quote"
                    visible={this.state.isModalVisible}
                    onOk={this.onModalOk}
                    onCancel={this.onModalCancel}
                    width={'80%'}
                >
                    <Table
                        rowKey={record => record.id}
                        size="small"
                        showHeader={false}
                        columns={columns}
                        dataSource={this.state.modalQuotes}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: (event) => this.onModalQuoteSelect(event, record)
                            };
                        }}
                    />
                </Modal>

                <CustomerEditFrm
                    title={this.state.customerId > 0 ? 'Edit Customer' : 'Create Customer'}
                    token={this.state.token}
                    ref={(ref) => { this.customerEditFrm = ref; }}
                    visible={this.state.customerEditFrmIsOpen}
                    onSubmit={this.onCustomerEditFrmSubmit}
                    onClose={this.onCustomerEditFrmClose}
                    customerId={this.state.customerId}
                />

                <CustomerSearchFrm
                    title='Select Customer'
                    token={this.state.token}
                    ref={(ref) => { this.customerSearchFrm = ref; }}
                    visible={this.state.customerSearchFrmIsOpen}
                    onSelect={this.onCustomerSearchFrmSelect}
                    onClose={this.onCustomerSearchFrmClose}
                />

                <ContractorEditFrm
                    title={this.state.contractorId > 0 ? 'Edit Contractor' : 'Create Contractor'}
                    token={this.state.token}
                    ref={(ref) => { this.contractorEditFrm = ref; }}
                    visible={this.state.contractorEditFrmIsOpen}
                    onSubmit={this.onContractorEditFrmSubmit}
                    onClose={this.onContractorEditFrmClose}
                    customerId={this.state.contractorId}
                />

                <ContractorSearchFrm
                    title='Select Contractor'
                    token={this.state.token}
                    ref={(ref) => { this.contractorSearchFrm = ref; }}
                    visible={this.state.contractorSearchFrmIsOpen}
                    onSelect={this.onContractorSearchFrmSelect}
                    onClose={this.onContractorSearchFrmClose}
                />

                <Modal
                    title="Calculation"
                    visible={this.state.isShowCalculate}
                    width={'80%'}
                    onCancel={() => this.setState({ isShowCalculate: false })}
                    footer={[
                        <Button key="back" onClick={() => this.setState({ isShowCalculate: false })}>Return</Button>,
                    ]}
                >
                    {this.state.isShowCalculate
                        ? this.renderCalculate()
                        : ''
                    }
                </Modal>

                <Modal
                    title="Export"
                    visible={this.state.isShowResult}
                    okText='Download'
                    cancelText='Close'
                    onOk={this.onDownloadResults}
                    onCancel={() => this.setState({ isShowResult: false })}
                    width={'80%'}
                >
                    {this.state.isShowResult
                        ? this.renderResults()
                        : ''
                    }
                </Modal>

            </Layout>
        );
        //        }
    }
}
