import React from 'react';
import { gloGetOptions, gloIsPresent, gloPrintIfPresent, gloDataUrl, gloDataPost, gloDataPut, gloDataPostWithResult, gloDataGet, gloGetTitleFromValue } from './Common.js';


export function getEquipment(p_data) {
    let result = <span>-</span>
    if (p_data.settingsJobType === 'installation') {

        /* Towers */
        let res_installation_towers = [];
        if (p_data.installationNumberOfTowers > 0) {
            for (let i = 0; i < p_data.installationNumberOfTowers; i++) {

                let tt = gloGetTitleFromValue(p_data.installationTowerTypeValues[i]);
                let tf = p_data.installationTowerFaucetsValues[i] > -1 ? p_data.installationTowerFaucetsValues[i] : p_data.installationTowerFaucetsCustomValues[i];
                let dt = p_data.installationDripTrays[i] ? 'Yes' : 'No';

                res_installation_towers.push(<li><b>Type:</b> {tt} <b># faucets:</b> {tf} <b>Product Drip Trays:</b> {dt}</li>)
            }
            res_installation_towers = <div>
                <b>Towers:</b>
                <ul>{res_installation_towers}</ul>
            </div>
        }

        /* Lacing kit */
        let res_installation_lacing_kit = '';
        if (p_data.installationIsLacingKit) {
            res_installation_lacing_kit = <div>
                <b>Lacing kit</b>
            </div>
        }


        /* Trunklines */
        let res_installation_trunklines = [];
        if (p_data.installationNumberOfTrunklines > 0) {
            for (let i = 0; i < p_data.installationNumberOfTrunklines; i++) {

                let tt = gloGetTitleFromValue(p_data.installationTrunklineTypes[i]);
                let tl = p_data.installationTrunklineValues[i];

                if (p_data.installationNumberOfTrunklines < 2) {
                    res_installation_trunklines.push(<li><b>Type:</b> {tt} <b>Length:</b> {tl}</li>)
                } else if (p_data.installationNumberOfTrunklines === 2) {
                    if (p_data.installationTrunklineYs[i]) {
                        res_installation_trunklines.push(<li><b>Type:</b> {tt} <b>Length:</b> {tl} <b>Ys:</b> Yes</li>)
                    } else {
                        res_installation_trunklines.push(<li><b>Type:</b> {tt} <b>Length:</b> {tl}</li>)
                    }

                } else if (p_data.installationNumberOfTrunklines > 2) {

                    let t_mq = p_data.installationTrunklineManifoldsQuantity[i];

                    res_installation_trunklines.push(
                        <li>
                            <b>Type:</b> {tt}
                            <b style={{ marginLeft: 10 }}>Length:</b> {tl}
                            {p_data.installationTrunklineYs[i] ? <span style={{ marginLeft: 10 }}><b>Ys:</b> Yes</span> : ''}
                            {p_data.installationTrunklineYs[i] ? <span style={{ marginLeft: 10 }}><b>Manifolds:</b> Yes</span> : ''}
                        </li>);
                }
            }

            res_installation_trunklines = <div>
                <b>Trunklines:</b>
                <ul>{res_installation_trunklines}</ul>
            </div>
        }
        /* Glycol units */
        let res_installation_glycol_units = [];
        if (p_data.installationNumberOfGlycolUnits > 0) {
            for (let i = 0; i < p_data.installationNumberOfGlycolUnits; i++) {
                let gt = gloGetTitleFromValue(p_data.installationGlycolUnitValues[i]);
                res_installation_glycol_units.push(<li><b>Type:</b> {gt}</li>);
            }

            res_installation_glycol_units = <div>
                <b>Glycol units:</b>
                <ul>{res_installation_glycol_units}</ul>
            </div>
        }

        /* Air source */
        let res_installation_air_source = [];
        if (p_data.installationIsBlenderBox) {
            if (p_data.installationBlenderBoxPrimaryCo2Regulator) {
                res_installation_air_source.push(
                    <li style={{ marginLeft: 10, marginRight: 10 }}><b>Primary Co2 Regulator</b></li>
                );
            }
            if (p_data.installationBlenderBoxPrimaryN2Regulator) {
                res_installation_air_source.push(
                    <li style={{ marginLeft: 10, marginRight: 10 }}><b>Primary N2 Regulator</b></li>
                );
            }
            if (p_data.installationBlenderBoxGmixRegulator) {
                res_installation_air_source.push(
                    <li style={{ marginLeft: 10, marginRight: 10 }}><b>Gmix Regulator</b></li>
                );
            }
        } else {
            res_installation_air_source.push(
                <li style={{ marginLeft: 10, marginRight: 10 }}><b>Primary Co2 Regulator,  Primary N2 Regulator</b></li>
            )
        }

        if (p_data.installationIsSingleportLeakDetector) {
            res_installation_air_source.push(
                <li style={{ marginLeft: 10, marginRight: 10 }}><b>Singleport leak detector</b></li>
            )

        }

        if (p_data.installationIsDoubleportLeakDetector) {
            res_installation_air_source.push(
                <li style={{ marginLeft: 10, marginRight: 10 }}><b>Doubleport leak detector</b></li>
            )
        }

        res_installation_air_source = <div><b>Air source:</b><ul>{res_installation_air_source}</ul></div>


        /* Walk in Panels */
        let res_installation_walk_in_panels = '';

        if (p_data.installationWalkinPanelsQuantity > 0) {
            if (p_data.installationWalkinPanelsType !== '') {
                let nm = gloGetTitleFromValue(p_data.installationWalkinPanelsType);
                res_installation_walk_in_panels = <div><b>Walk in Panels</b><li>{nm}</li></div>
            }
        }

        /* Glycol Jumper line */
        let res_installation_glycol_jumper_line = '';
        if (p_data.installationGlycolJumperLine > 0) {
            res_installation_glycol_jumper_line = <div><b>Glycol Jumper line</b></div>
        }        

        result = [ 
            res_installation_towers, 
            res_installation_lacing_kit, 
            res_installation_trunklines, 
            res_installation_glycol_units, 
            res_installation_air_source, 
            res_installation_walk_in_panels,
            res_installation_glycol_jumper_line
        ];

    }
    return result;
}