import Axios from 'axios';
import React from 'react';
import { message, Select } from 'antd';

export const gloDataUrl = "https://perfectpour.das.to/v7/public/api"
export const gloRootUrl = "https://perfectpour.das.to"

//export const gloDataUrl = "http://ppa/api"
//export const gloRootUrl = "http://ppa"

//export const gloDataUrl = "http://pp/api"

export function gloDataGet(pComponentName, pUrl, pFunc) {
    console.log(`${pComponentName} gloDataGet pUrl`, pUrl);
    Axios.get(pUrl)
        .then((res) => {
            console.log(`${pComponentName} gloDataGet res`, res);
            pFunc(res)
        }).catch((error) => { gloHandleError(error) });
}

export function gloGetOptions(p_name, p_data, p_index) {
    const Option = Select.Option;
    let result = p_data.sort().map(x => {
        let l = x.toLowerCase().replace(new RegExp('[ ]', 'g'), '_');
        return <Option key={`${p_name}_${p_index}_${l}`} value={l}>{x}</Option>
    });
    return result;
}

export function gloGetTitleFromValue(p_value) {
    if (p_value === '') return 'Unknown';
    let result = p_value.toLowerCase()
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return result;
}

export function gloGetTrunklineTitleFromValue(p_value) {

    let result = 'Unknown';

    if (p_value === 'crusader_2_4') {
        result = 'Crusader [2+4]';
    } else if (p_value === 'crusader_2_6') {
        result = 'Crusader [2+6]';
    } else if (p_value === 'crusader_2_8') {
        result = 'Crusader [2+8]';
    } else if (p_value === 'crusader_2_10') {
        result = 'Crusader [2+10]';
    } else if (p_value === 'crusader_4_10') {
        result = 'Crusader [4+10]';
    }

    return result;
}

export function gloGetGlycolUnitTitleFromValue(p_value) {

    let result = 'Unknown';

    if (p_value === 'crusader_225') {
        result = 'Crusader Model 225';
    } else if (p_value === 'crusader_350') {
        result = 'Crusader Model 350';
    } else if (p_value === 'perlick_quickchill_1_3_hp') {
        result = 'Perlick Quickchill 1/3 HP';
    } else if (p_value === 'perlick_4404_1_2_hp') {
        result = 'Perlick 4404 1/2 HP';
    }

    return result;
}

export function gloDataPut(pComponentName, pUrl, pPayload, pFunc, pMessage) {
    Axios.put(pUrl, pPayload)
        .then((res) => {
            console.log(` .. ${pComponentName} gloDataPut result`, res);
            gloHandleSuccess(pMessage);
            pFunc();
        }).catch((error) => { 
            gloHandleError(error) 
        })
}

export function gloDataPost(pComponentName, pUrl, pPayload, pFunc, pMessage) {
    Axios.post(pUrl, pPayload)
        .then((res) => {
            console.log(` .. ${pComponentName} gloDataPost result`, res);
            gloHandleSuccess(pMessage);
            pFunc();
        }).catch((error) => {
            gloHandleError(error)
        })
}

export function gloDataPostWithResult(pComponentName, pUrl, pPayload, pFunc, pMessage) {
    Axios.post(pUrl, pPayload)
        .then((res) => {
            console.log(` .. ${pComponentName} gloDataPostWithResult result`, res);
            gloHandleSuccess(pMessage);
            pFunc(res);
        }).catch((error) => {
            pFunc(undefined);
            gloHandleError(error);
        })
}

export function gloHandleSuccess(pMessage) {
    if (typeof pMessage !== 'undefined') {
        message.success(pMessage);
    } else {
        message.success('Done');
    }
}

export function gloHandleError(pError) {
    console.log("gloHandleError1", pError.message);
    //message.error(pError.message);
    console.log("gloHandleError2", pError);
    if (pError.response) {
        if (pError.response.status === 401) {
            window.ppaComponent._logoutUser();
        }
    } else {
        window.ppaComponent._logoutUser();
    }
}

export function gloIsPresent(p_value) {
    return (p_value && p_value !== undefined && p_value !== '');
}

export function gloPrintIfPresent(p_value) {
    return gloIsPresent(p_value) ? p_value : '';
}

export function gloRenderPrice(p_value) {
    //    return `$${p_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (p_value === 0) {
        return '$0';
    } else {
        var val = Math.round((Number(p_value) * 100)) / 100;
        var parts = val.toString().split(".");
        let p0 = "$" + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let p1 = ((parts[1] ? "." + parts[1] : ".") + "00").substr(0,3);
        let result = "$" + parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
        //console.log("gloRenderPrice", p_value, val, parts, result);
        return p0 + p1; // result;
    }
}
