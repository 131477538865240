export const TowerTypes = [
        'THT Tower',
        'Pirogue',
        'Rouxgaroux Pipe Tower Center Mount',
        'Dos Gris Pipe Tower',
        'Mardi Gras Pipe Tower',
        'Vielle Black Iron Pipe Tower',
        'Yeti Non Frozen',
        'Yeti Frozen',
        'Beau Coup',
        'Caimon',
        'Under Counter (bottom of bartop mount)',
        'Wall Mount (stainless wall plate)',
        'Mushroom',
        'Mini Mushroom',
        'Metro T',
        'BrauHaus',
        'Biergarten',
        'Titan U',
        'Pass Through',
        'Tapped Pipe Tower',
        'Custom'
    ];

 