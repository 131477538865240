import React from 'react';
import { gloIsPresent, gloPrintIfPresent } from './Common.js';

const componentName = 'AddressText';

export default class AddressText extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(props) {
    }

    render() {

        const divider = (gloIsPresent(this.props.address) && (gloIsPresent(this.props.city) || gloIsPresent(this.props.state) || gloIsPresent(this.props.zip))) ? ' | ' : '';
        const quote = (gloIsPresent(this.props.state) || gloIsPresent(this.props.zip)) ? ', ' : '';
        const blank = gloIsPresent(this.props.zip) ? ' ' : '';
        const br = gloIsPresent(this.props.phone) || gloIsPresent(this.props.email)
        const divider2 = (gloIsPresent(this.props.phone) && gloIsPresent(this.props.email)) ? ', ' : '';
        return (
            <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: 20 }}>
                <div style={{ fontSize: 12, fontWeight: 'bold', textAlign: 'left', lineHeight: 1, marginBottom: 5, marginTop: 5 }}>
                    {this.props.title}
                </div>
                <div style={{ textAlign: 'left', lineHeight: 1 }}>
                    <p>{gloPrintIfPresent(this.props.address)}{divider}{gloPrintIfPresent(this.props.city)}{quote}{gloPrintIfPresent(this.props.state)}{blank}{gloPrintIfPresent(this.props.zip)}
                       {br}{gloPrintIfPresent(this.props.phone)}{divider2}{gloPrintIfPresent(this.props.email)}</p>
                </div>
            </div>
        );
    }
}

