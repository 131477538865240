import React from 'react';
import { Button, Modal, Table } from 'antd';
import { gloIsPresent, gloPrintIfPresent, gloDataUrl, gloDataGet } from './Common.js';
import AddressText from './AddressText.js';

const componentName = 'CustomerSearchForm';

export default class CustomerSearchForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerId: 0
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${componentName} componentDidMount`);
        this.props.form.validateFields();
    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.visible !== this.props.visible) && nextProps.visible) {
            console.log('componentWillReceiveProps nextProps', nextProps)
            this.loadData();
        }
    }

    loadData = () => {
        gloDataGet(componentName, `${gloDataUrl}/customers?token=${this.props.token}`, this.onDataLoaded)
    }

    onDataLoaded = (p_data) => {
        console.log(`${componentName} onDataLoaded`, p_data);
        this.setState({ data: p_data.data });
    }

    render() {

        console.log(`${componentName} render props`, this.props);
        console.log(`${componentName} render state`, this.state);

        const footer = [
            <Button key="back" onClick={this.props.onClose}>Close</Button>,
        ];

        const columns = [
            {
                title: 'Customer', key: 'name', render: x => (
                    <div>
                        <div style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'left', lineHeight: 1, marginBottom: 10, marginTop: 20 }}>
                            {x.name}
                        </div>
                        <AddressText
                            title=''
                            address={x.address}
                            city={x.city}
                            state={x.state}
                            zip={x.zip}
                            phone={x.phone}
                            email={x.email}
                        />
                    </div>
                )
            },
        ];

        return (
            <Modal
                visible={this.props.visible}
                title={this.props.title}
                onCancel={this.props.onClose}
                onOk={this.props.onClose}
                footer={footer}
            >
                <Table
                    rowKey={record => record.id}
                    size="small"
                    showHeader={false}
                    columns={columns}
                    dataSource={this.state.data}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => this.props.onSelect(event, record)
                        };
                    }}
                />
            </Modal>
        );
    }
}

