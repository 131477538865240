import React from 'react';
import { Button, Modal, Form, Input, InputNumber, message } from 'antd';
import { gloDataUrl, gloDataPostWithResult } from './Common.js';

const FormItem = Form.Item;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const componentName = 'ContractorEditForm';

export default class ContractorEditForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            cotractorId: 0
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${componentName} componentDidMount`);
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(`${componentName} handleSubmit values`, values);

                var payload = {
                    name: values.name,
                    contact: values.contact,
                    phone: values.phone,
                    email: values.email
                };                

                console.log(` .. ${componentName} handleSubmit payload`, payload);

                var url = `${gloDataUrl}/contractors?token=${this.props.token}`;

                console.log(` .. ${componentName} handleSubmit url`, url);

                gloDataPostWithResult(componentName, url, payload, this.props.onSubmit, 'Contractor data saved')
            }
        });
    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.visible !== this.props.visible) && nextProps.visible) {
            console.log('componentWillReceiveProps nextProps', nextProps)
            this.setState({ 
                customerId: nextProps.customerId
            })
        }
    }

    render() {

        console.log(`${componentName} render props`, this.props);
        console.log(`${componentName} render state`, this.state);

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const nameError = isFieldTouched('name') && getFieldError('name');
        const contactError = isFieldTouched('contact') && getFieldError('contact');
        const phoneError = isFieldTouched('phone') && getFieldError('phone');
        const emailError = isFieldTouched('email') && getFieldError('email');
        
        var footer = [
            <Button key="back" onClick={this.props.onClose}>Close</Button>,
            <Button key="submit" type="primary"
                onClick={this.handleSubmit}
                disabled={hasErrors(getFieldsError())}
            >Save</Button>,
        ];

        return (
            <Modal
                visible={this.props.visible}
                title={this.props.title}
                okText="OK"
                onCancel={this.props.onClose}
                onOk={this.handleSubmit}
                footer={footer}
            >
                {this.state.desc}
                <Form layout="horizontal">
                    <FormItem
                        label='Name'
                        validateStatus={nameError ? 'error' : ''}
                        help={nameError || ''}
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Contact'
                        validateStatus={contactError ? 'error' : ''}
                        help={contactError || ''}
                    >
                        {getFieldDecorator('contact', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Phone'
                        validateStatus={phoneError ? 'error' : ''}
                        help={phoneError || ''}
                    >
                        {getFieldDecorator('phone', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Email'
                        validateStatus={emailError ? 'error' : ''}
                        help={emailError || ''}
                    >
                        {getFieldDecorator('email', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

