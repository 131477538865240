import React from 'react';
import { Button, Modal, Form, Input, InputNumber, message } from 'antd';
import { gloDataUrl, gloDataPostWithResult } from './Common.js';

const FormItem = Form.Item;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const componentName = 'CustomerEditForm';

export default class CustomerEditForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerId: 0
        }
    }

    componentDidMount(props) {
        // To disabled submit button at the beginning.
        console.log(`${componentName} componentDidMount`);
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log(`${componentName} handleSubmit values`, values);

                var payload = {
                    name: values.name,
                    address: values.address,
                    city: values.city,
                    state: values.state,
                    zip: values.zip,
                    email: values.email,
                    phone: values.phone,
                    owner: values.owner,
                    owner_cell: values.ownerCell,
                    manager: values.manager,
                    manager_cell: values.managerCell
                };                

                console.log(` .. ${componentName} handleSubmit payload`, payload);

                var url = `${gloDataUrl}/customers?token=${this.props.token}`;

                console.log(` .. ${componentName} handleSubmit url`, url);

                gloDataPostWithResult(componentName, url, payload, this.props.onSubmit, 'Customer data saved')
            }
        });
    }

    componentWillReceiveProps(nextProps) {

        if ((nextProps.visible !== this.props.visible) && nextProps.visible) {
            console.log('componentWillReceiveProps nextProps', nextProps)
            this.setState({ 
                customerId: nextProps.customerId
            })
        }
    }

    render() {

        console.log(`${componentName} render props`, this.props);
        console.log(`${componentName} render state`, this.state);

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        // Only show error after a field is touched.
        const nameError = isFieldTouched('name') && getFieldError('name');
        const addressError = isFieldTouched('address') && getFieldError('address');
        const cityError = isFieldTouched('city') && getFieldError('city');
        const stateError = isFieldTouched('state') && getFieldError('state');
        const zipError = isFieldTouched('zip') && getFieldError('zip');
        
        var footer = [
            <Button key="back" onClick={this.props.onClose}>Close</Button>,
            <Button key="submit" type="primary"
                onClick={this.handleSubmit}
                disabled={hasErrors(getFieldsError())}
            >Save</Button>,
        ];

        return (
            <Modal
                visible={this.props.visible}
                title={this.props.title}
                okText="OK"
                onCancel={this.props.onClose}
                onOk={this.handleSubmit}
                footer={footer}
            >
                {this.state.desc}
                <Form layout="horizontal">
                    <FormItem
                        label='Name'
                        validateStatus={nameError ? 'error' : ''}
                        help={nameError || ''}
                    >
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Address'
                        validateStatus={addressError ? 'error' : ''}
                        help={addressError || ''}
                    >
                        {getFieldDecorator('address', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='City'
                        validateStatus={cityError ? 'error' : ''}
                        help={cityError || ''}
                    >
                        {getFieldDecorator('city', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='State'
                        validateStatus={stateError ? 'error' : ''}
                        help={stateError || ''}
                    >
                        {getFieldDecorator('state', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Zip'
                        validateStatus={zipError ? 'error' : ''}
                        help={zipError || ''}
                    >
                        {getFieldDecorator('zip', {
                            rules: [{ required: true, message: '' }],
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Email'
                    >
                        {getFieldDecorator('email', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Phone'
                    >
                        {getFieldDecorator('phone', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Owner'
                    >
                        {getFieldDecorator('owner', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Owner cell'
                    >
                        {getFieldDecorator('ownerCell', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Manager'
                    >
                        {getFieldDecorator('manager', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                    <FormItem
                        label='Manager cell'
                    >
                        {getFieldDecorator('managerCell', {
                            
                        })(
                            <Input />
                        )}
                    </FormItem>
                </Form>
            </Modal>
        );
    }
}

