import React, { Component } from 'react';
//import logo from './logo.svg';
import './App.css';
//import MobileDetect from 'mobile-detect'
//import DesktopApp from './DesktopApp.js';
import MobileApp from './MobileApp';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import Login from "./Login";
import { gloDataUrl, gloDataGet } from './Common.js';


/*
const md = new MobileDetect(window.navigator.userAgent);
{md.mobile() === null
    ? <DesktopApp />
    : <MobileApp />
}
*/

//const api_url = 'https://perfectpour.das.to/v7/public/api/';
//const api_url = 'http://ppa/api/';
//const api_url = 'https://perfectpour.das.to/v7/public/api/';
//const api_url = 'http://ppa/api/';
const componentName = "App"

class App extends Component {

    constructor() {
        super();
        this.state = {
            isLoggedIn: false,
            user: {},
        };
    }

    componentDidMount() {
        this.checkToken();

        window.ppaComponent = this;
        console.log("App componentDidMount 2");
    }

    checkToken = () => {

        let token = localStorage["appState"] ? JSON.parse(localStorage["appState"]).user.auth_token : "";  
        console.log("App checkToken", token);     
        gloDataGet(componentName, `${gloDataUrl}/check?token=${token}`, this.onTokenChecked);

    }

    onTokenChecked = () => {

        let state = localStorage["appState"];
        console.log("App componentDidMount 1");
        if (state) {
            let AppState = JSON.parse(state);
            console.log("componentDidMount", AppState);
            this.setState({ isLoggedIn: AppState.isLoggedIn, user: AppState });
        }
    }

    _loginUser = (email, password) => {
        $("#login-form button")
            .attr("disabled", "disabled")
            .html(
                '<i class="fa fa-spinner fa-spin fa-1x fa-fw"></i><span class="sr-only">Loading...</span>'
            );
        var formData = new FormData();
        formData.append("email", email);
        formData.append("password", password);

        axios
            .post(`${gloDataUrl}/user/login`, formData)
            .then(response => {
                console.log(response);
                return response;
            })
            .then(json => {
                if (json.data.success) {
                    //alert("Login Successful!");

                    let userData = {
                        name: json.data.data.name,
                        id: json.data.data.id,
                        email: json.data.data.email,
                        auth_token: json.data.data.auth_token,
                        timestamp: new Date().toString()
                    };
                    let appState = {
                        isLoggedIn: true,
                        user: userData
                    };
                    // save app state with user date in local storage
                    localStorage["appState"] = JSON.stringify(appState);
                    this.setState({
                        isLoggedIn: appState.isLoggedIn,
                        user: appState.user
                    });
                } else {
                    //alert("Login Failed!");
                }

                $("#login-form button")
                    .removeAttr("disabled")
                    .html("Login");
            })
            .catch(error => {
                //alert(`An Error Occured! ${error}`);
                $("#login-form button")
                    .removeAttr("disabled")
                    .html("Login");
            });
    };

    _logoutUser = () => {
        let appState = {
            isLoggedIn: false,
            user: {}
        };
        // save app state with user date in local storage
        localStorage["appState"] = JSON.stringify(appState);
        this.setState(appState);
    };

    render() {
        console.log("App props", this.props);
        return (
            <div className="App">
                {this.state.isLoggedIn
                    ? <Router>
                        <Switch>
                            <Route path="/" component={MobileApp} />
                        </Switch>
                    </Router>
                    : <Login {...this.props}  loginUser={this._loginUser} />
                }
            </div>
        );
    }
}

export default App;
